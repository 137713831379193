import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForApplications = axios.create();

axiosApiInstanceForApplications.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForApplications(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class ApplicationsService {

    getApplicationsFiltered(filter, orderBy, page, pageSize) {

        let filterToSend = "";

        if (orderBy)
            filterToSend = orderBy;


        if (page)
            if (filterToSend !== "")
                filterToSend += "&page=" + page;
            else
                filterToSend = "?page=" + page;

        if (pageSize)
            if (filterToSend !== "")
                filterToSend += "&pageSize=" + pageSize;
            else
                filterToSend = "?pageSize=" + pageSize;

        if (filter)
            if (filterToSend !== "")
                filterToSend += "&filter=" + filter;
            else
                filterToSend = "?filter=" + filter;

        return axiosApiInstanceForApplications.get(API_URL + '/V3/Applications' + filterToSend, { headers: authHeader() });
    }

    getApplicationByApplicationId(applicationId) {
        return axiosApiInstanceForApplications.get(API_URL + '/V3/Applications/' + applicationId, { headers: authHeader() });
    }

    deleteApplication(applicationId) {
        return axiosApiInstanceForApplications.delete(API_URL + '/V3/Applications/' + applicationId, { headers: authHeader() });
    }

    updateApplication(applicationId, Application) {
        return axiosApiInstanceForApplications.put(API_URL + '/V3/Applications/' + applicationId, Application, { headers: authHeader() });
    }

    insertApplication(Application) {
        return axiosApiInstanceForApplications.post(API_URL + '/V3/Applications', Application, { headers: authHeader() });
    }

    getLicenseTagByApplication(applicationId) {
        return axiosApiInstanceForApplications.get(API_URL + '/V3/Applications/' + applicationId + '/Tags', { headers: authHeader() });
    }

    deleteLicenseTagsByApplication(applicationId, tagName) {
        return axiosApiInstanceForApplications.delete(API_URL + '/V3/Applications/' + applicationId + '/Tags/' + tagName, { headers: authHeader() });
    }

    updateLicenseTagByApplication(applicationId, tagName, LicenseTag) {
        return axiosApiInstanceForApplications.put(API_URL + '/V3/Applications/' + applicationId + '/Tags/' + tagName, LicenseTag, { headers: authHeader() });
    }

    insertTagLicenseTagsByApplication(applicationId, LicenseTag) {
        return axiosApiInstanceForApplications.post(API_URL + '/V3/Applications/' + applicationId + '/Tags/', LicenseTag, { headers: authHeader() });
    }
}

export default new ApplicationsService();
