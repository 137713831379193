import axios from "axios";
import MD5 from "crypto-js/md5";
import jwt_decode from "jwt-decode";

const API_URL = process.env.REACT_APP_API_AUTH_URL;
const COOKIE_NAME = "user_info_JWT";


class AuthService {
    login(username, password) {
        return axios
            .post(API_URL + "token", {
                username: username,
                password: MD5(password).toString(),
                applicationCode: 'AGRF_LIC_WEBAPP',
                solutionCode: 'AGRF_LIC',
                initialLanguage: 'English',
                rememberMe: true
            })
            .then(response => {
                if (response.data) {
                    this.SaveCookie(response.data);
                }

                return response.data;
            });
    }

    relogin() {
        return axios
            .post(API_URL + "refreshToken", {
                token: this.GetCookie(),
                solutionCode: 'AGRF_LIC'
            })
            .then(response => {

                this.SaveCookie(response.data);
                return response.data;

            }).catch(() => {
                this.DeleteCookie();
                if (process.env.NODE_ENV === "production")
                    window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_LIC_WEBAPP&solution=AGRF_LIC');
                else
                    window.location.href = '/login';
            });
    }

    logout() {
        this.DeleteCookie();
        if (process.env.NODE_ENV === "production")
            window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_LIC_WEBAPP&solution=AGRF_LIC');
        else
            window.location.href = '/login';
    }

    getCurrentUser() {
        return this.GetCookie();
    }

    isUserAdmin() {

        let cookie = this.GetCookie();

        // Comprovació: si no existeixen les dades d'usuari, no és admin
        if (!cookie)
            return false;

        let listOfComponents = JSON.parse(jwt_decode(cookie).components);

        if (listOfComponents !== null && listOfComponents.length > 0) {
            return listOfComponents.includes("AGRF_LIC_ADMINCMP");
        }
        else
            return false;

    }

    isLogged() {

        // Comprovació: si no existeixen les dades d'usuari, no és admin
        return !(!this.GetCookie());

    }

    GetCookie() {

        let cookieValue = document.cookie
            .split('; ')
            .find((row) => row.startsWith(COOKIE_NAME))
            ?.split('=')[1];


        return cookieValue ? cookieValue : null;
    }

    SaveCookie(cookieValue) {

        this._CreateCookie(COOKIE_NAME, cookieValue, 10);


    }

    DeleteCookie() {

        this._CreateCookie(COOKIE_NAME, "", -1);

    }

    _CreateCookie(name, value, days) {

        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        let cookieText = name + "=" + value + expires + "; path=/";

        if (process.env.NODE_ENV === "production") // is deployed
            cookieText += ";domain=agrifoodatsolutions.com";

        document.cookie = cookieText;
    }

}

export default new AuthService();

