import * as React from 'react';
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from "react-i18next";

import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";


import { CustomInput } from "../../components/CustomComponents";
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import LicencesService from "../../services/licenses.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function List(props) {

    const { t } = useTranslation();

    // Grid Hooks
    const [total, setTotal] = React.useState(0);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);

    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Delete Event Hooks
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [dataItemToRemove, setDataItemToRemove] = React.useState({});

    // Data Hooks
    const [licencesData, setLicencesData] = React.useState([]);

    // Loading Hooks
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    // Hooks
    const [tags, setTags] = React.useState([]);
    const [selectedClient, setSelectedClient] = React.useState(null);

    const licenseTag = {
        "tagName": "",
        "value": ""
    };

    const loadData = React.useCallback((params) => {

        setLoading(true);

        LicencesService.getLicenseTagsFiltered(props.licenseId)
            .then(response => response.data)
            .then(json => {
                let dataToShow = json.contract.filter(y => y.hasValue === true && y.isDisabled === false);
                setLicencesData(dataToShow);
                setTotal(dataToShow.length);
                setLoading(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
            });
    }, [props]);

    const loadTags = React.useCallback(() => {

        LicencesService.getLicenseTagsFiltered(props.licenseId)
            .then(response => response.data)
            .then(json => {
                let filteredList = json.contract.filter(y => y.hasValue === false && y.isDisabled === false);
                setTags(filteredList);
            });

    }, [props]);

    React.useEffect(() => {
        loadTags();
    }, [props.licenseId, loadTags]);

    const handleSubmit = React.useCallback((dataItem) => {

        setSaving(true);
        LicencesService.insertLicenseTag(props.licenseId, selectedClient.tagName, dataItem.value).then(() => {
            loadTags();
            loadData();
            setSelectedClient(null);
            setSaving(false);
        }).catch((err) => {
            setVisibleError(true);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }, [props, selectedClient, loadTags, loadData]);

    // Aspect Functions

    const CommandCell = React.useCallback((cellProps) => (
        <CustomListCommandCellWithRemove
            {...cellProps}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/Clients/' + props.client.clientId + '/Licenses/' + props.licenseId  + "/Tags/" +cellProps.dataItem.tagName + '/Details'}
            isAdmin={props.isAdmin}
        />
    ), [props]);

    React.useEffect(() => {

        loadData();

    }, [props.licenseId, loadData]);


    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    }, []);

    // Action Functions

    const remove = (dataItem) => {

        setDataItemToRemove(dataItem);
        setVisibleRemove(true);

    }

    const removeDataItem = () => {
        setLoading(true);
        LicencesService.deleteLicenseTag(props.licenseId, dataItemToRemove.tagName).then(() => {
            setDataItemToRemove({});
            loadData();
            loadTags();
            setVisibleRemove(false);
        }).catch((err) => {
            setDataItemToRemove({});
            setVisibleError(true);
            setVisibleRemove(false);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }

    const closeDialogRemoveCallback = React.useCallback(() => { setVisibleRemove(false) }, []);

    const closeDialogErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);

    const selectedTagCallback = React.useCallback(e => {
        setSelectedClient(e.value);
    }, []);

    const formCallback = React.useCallback((formRenderProps) => (
        <FormElement>
            <fieldset>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("LicenseTags_Details_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4">
                                <DropDownList
                                    label={t("LicenseTag_TagName_Label")}
                                    name="tagName"
                                    textField="tagName"
                                    data={tags}
                                    dataItemKey="tagName"
                                    style={{ width: '100%' }}
                                    value={selectedClient}
                                    onChange={selectedTagCallback}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseTags_Value_Label")}
                                    name={'value'}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div className="float-end k-form-buttons">
                {props.isAdmin ? <button
                    type={'submit'}
                    disabled={!saving && (selectedClient === null)}
                    className="k-button"
                >
                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                </button> : ""}
            </div>
        </FormElement>), [saving, t, selectedClient, tags, selectedTagCallback, props]);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeDialogErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeDialogErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {visibleRemove && (
                <Dialog title={t("Global_RemoveDialog_Title")} onClose={closeDialogRemoveCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{t("Global_RemoveDialog_Text") + " \"" + dataItemToRemove.tagName + "\" ?"} </b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => removeDataItem()}>
                            {t("Global_OKButton_Text")}
                        </button>
                        <button className="k-button" onClick={closeDialogRemoveCallback}>
                            {t("Global_CancelButton_Text")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>

                    {loading && loadingPanel}

                    <Form
                        onSubmit={handleSubmit}
                        initialValues={licenseTag}
                        key={JSON.stringify(licenseTag)}
                        render={formCallback}
                    />
                    <br />
                    <br />
                    <br />
                    {loading ? <Skeleton /> : <Grid
                        editField='inEdit'
                        cellRender={loadingCell}
                        data={licencesData}
                        total={total}
                        //onDataStateChange={e => dataClientSearchTextBoxStateChange(e)}
                        scrollable={'none'}
                        sortable={{
                            allowUnsort: true,
                            mode: "single"
                        }}
                    >
                        {false ? <GridToolbar>
                            <div>
                                <Link to={'/licenses/' + props.licenseId + '/Tags/Add'}>
                                    <button title={t("LicenseTag_Button_Tooltip_Create")} className="k-button k-primary">
                                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                                    </button>
                                </Link>
                            </div>
                        </GridToolbar> : ""}
                        <Column cell={CommandCell} width={props.isAdmin ? "130px" : "80px"} sortable={false} />
                        <Column field="tagName" title={t("LicenseTags_tagName_Label")} />
                        <Column field="value" title={t("LicenseTags_Value_Label")} />
                    </Grid>}
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}

