import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForContracts = axios.create();

axiosApiInstanceForContracts.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForContracts(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class ContractsService {

    
    getContractsFiltered(filter, orderBy, page, pageSize) {

        let filterToSend = "";

        if (orderBy)
            filterToSend = orderBy;


        if (page)
            if (filterToSend !== "")
                filterToSend += "&page=" + page;
            else
                filterToSend = "?page=" + page;

        if (pageSize)
            if (filterToSend !== "")
                filterToSend += "&pageSize=" + pageSize;
            else
                filterToSend = "?pageSize=" + pageSize;

        if (filter)
            if (filterToSend !== "")
                filterToSend += "&filter=" + filter;
            else
                filterToSend = "?filter=" + filter;

        return axiosApiInstanceForContracts.get(API_URL + '/V3/Contracts' + filterToSend, { headers: authHeader() });
    }

    getContract(contractId) {
        return axiosApiInstanceForContracts.get(API_URL + '/V3/Contracts/' + contractId, { headers: authHeader() });
    }

    deleteContract(contractId) {
        return axiosApiInstanceForContracts.delete(API_URL + '/V3/Contracts/' + contractId, { headers: authHeader() });
    }

    updateContract(contractId, Contract) {
        return axiosApiInstanceForContracts.put(API_URL + '/V3/Contracts/' + contractId, Contract, { headers: authHeader() });
    }

    insertContract(Contract) {
        return axiosApiInstanceForContracts.post(API_URL + '/V3/Contracts', Contract, { headers: authHeader() });
    }

    async getAllClients() {

        let clientsTotalN = await axiosApiInstanceForContracts.get(API_URL + '/V3/Clients/', { headers: authHeader() });
        let listOfTotalClients = await axiosApiInstanceForContracts.get(API_URL + '/V3/Clients/?pageSize='+clientsTotalN.data.totalResults+'&orderBy=Name', { headers: authHeader() });
        return listOfTotalClients.data.contract;

    }
}

export default new ContractsService();
