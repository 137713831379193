import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton';

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { filterBy } from "@progress/kendo-data-query";

import { CustomInput, CustomCheckbox, CustomDatePickerInput, CustomTextArea } from "../../components/CustomComponents";
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import LicenseRequestService from "../../services/licenserequests.service";
import LicenseService from "../../services/licenses.service";
import ClientsService from "../../services/clients.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Edit(props) {

    const { t } = useTranslation();
    const history = useHistory();

    // Loading hooks
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    // Data hooks
    const [clients, setClients] = React.useState([]);
    const [filteredClients, setFilteredClients] = React.useState([]);
    const [selectedClient, setSelectedClient] = React.useState({ clientId: -1, name: "", remarks: '' });
    const [applicationsData, setApplicationsData] = React.useState([]);
    const [selectedApplication, setSelectedApplication] = React.useState(null);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Hooks

    React.useEffect(() => {
        ClientsService.getClients()
            .then(response => response.data)
            .then(json => {
                setClients(json);
                setFilteredClients(json);
            }).catch(err => {
                setVisibleError(true);
                setSaving(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });;

    }, []);


    const [license, setLicense] = React.useState({
        "allowUpdates": false,
        "appId": "",
        "creationDate": Date.now(),
        "licenseCode": "",
        "licenseId": -1,
        "isDisabled": false,
        "clientId": -1,
        "licenseRequestId": props.license.licenseRequestId ? props.licenselicenseRequestId : "",
        "machineCode": "",
        "remarks": "",
        "maxVersion": ""
    });


    React.useEffect(() => {

        if (props.license.licenseId !== -1) {
            LicenseRequestService.getListOfApplications().then(data => {
                setLicense({
                    "allowUpdates": props.license.allowUpdates,
                    "appId": props.license.appId,
                    "creationDate": new Date(props.license.creationDate),
                    "licenseCode": props.license.licenseCode,
                    "licenseId": props.license.licenseId,
                    "isDisabled": props.license.isDisabled,
                    "clientId": props.license.clientId,
                    "licenseRequestId": props.license.licenseRequestId ?? "",
                    "machineCode": props.license.machineCode,
                    "remarks": props.license.remarks,
                    "maxVersion": props.license.maxVersion
                });

                setApplicationsData(data);
                setSelectedApplication(data.find(x => x.appId === props.license.appId));
                setSelectedClient(clients.find(z => z.clientId === props.license.clientId));

                setLoading(false);
            });
        }
    }, [props.license, clients, props.license.licenseRequestId]);

    // Events

    const handleSubmit = React.useCallback(async (dataItem) => {

        try {
            await LicenseService.updateLicense(license.licenseId, {
                "allowUpdates": dataItem.allowUpdates,
                "appId": dataItem.appId,
                "creationDate": new Date(dataItem.creationDate),
                "licenseCode": dataItem.licenseCode,
                "licenseId": license.licenseId,
                "isDisabled": dataItem.isDisabled,
                "clientId": selectedClient.clientId,
                "licenseRequestId": dataItem.licenseRequestId,
                "machineCode": dataItem.machineCode,
                "remarks": dataItem.remarks === "" ? null : dataItem.remarks,
                "maxVersion": dataItem.maxVersion === "" ? null : dataItem.maxVersion

            });

            setSaving(false);
            history.push("/licenses/" + license.licenseId + "/\"\"/Details");

        } catch (err) {

            setVisibleError(true);
            setSaving(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });

        }
    }, [history, license, selectedClient]);


    const filterChange = (event) => {
        setSelectedClient({});
        setFilteredClients(filterData(event.filter));
    };

    const filterData = (filter) => {
        const data = clients.slice();
        return filterBy(data, filter);
    };

    const closeDialogErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);

    const clickToClientData = React.useCallback(() => { history.push('/clients/' + selectedClient.clientId + '/Details') }, [history, selectedClient]);

    const clickToLicenseRequest = React.useCallback(() => { history.push('/LicenseRequests/' + license.licenseRequestId + '/Details') }, [history, license]);

    const formCallback = React.useCallback((formRenderProps) => {

        const changedSelectedClient = (e) => {
            setSelectedClient(e.value);
            formRenderProps.onChange("client", { value: e.value });
        };

        const changedSelectedApp = e => {
            setSelectedApplication(e.value);
            formRenderProps.onChange("appId", { value: e.value.appId });
        };

        return (
            <FormElement>
                <fieldset>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <b>{t("License_Details_Label")}</b>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4" >
                                    <Field
                                        label={t("License_LicenseId_Label")}
                                        name={'licenseId'}
                                        loading={loading}
                                        component={CustomInput}
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <Field
                                        label={t("License_CreationDate_Label")}
                                        name={'creationDate'}
                                        spinners={true}
                                        format="dd/MM/yyyy"
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomDatePickerInput}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    {loading ? <Skeleton /> : <DropDownList
                                        label={t("License_Application_Label")}
                                        name="application"
                                        textField="applicationName"
                                        data={applicationsData}
                                        dataItemKey="applicationId"
                                        style={{ width: '100%' }}
                                        value={selectedApplication}
                                        onChange={changedSelectedApp}
                                    />}
                                </div>
                                <div className="col-lg-3">
                                    <Field
                                        label={t("License_MaxVersion_Label")}
                                        name={'maxVersion'}
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomInput}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4" >
                                    {loading ? <Skeleton /> : <DropDownList
                                        label={t("License_ClientId_Label")}
                                        name="clientId"
                                        textField="name"
                                        data={filteredClients}
                                        dataItemKey="clientId"
                                        style={{ width: '100%' }}
                                        value={selectedClient}
                                        filterable={true}
                                        onFilterChange={filterChange}
                                        disabled={!props.isAdmin}
                                        onChange={changedSelectedClient}
                                    />}
                                </div>
                                <div className="col-lg-2" align="left" >
                                    {selectedClient && !loading ?
                                        (
                                            <div style={{ padding: "20px 0 0" }}><Button
                                                icon="user"
                                                title={t("License_ClientButtonLink_Tooltip")}
                                                onClick={clickToClientData}></Button></div>
                                        )
                                        : (<span />)}
                                </div>
                                <div className="col-lg-2">
                                    <Field
                                        label={t("License_IsDisabled_Label")}
                                        name={'isDisabled'}
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomCheckbox}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <Field
                                        label={t("License_AllowUpdates_Label")}
                                        name={'allowUpdates'}
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomCheckbox}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("License_Remarks_Label")}
                                        name={'remarks'}
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomTextArea}
                                    />
                                </div>

                            </div>
                            <div className="row">

                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <b>{t("License_Details_Codes_Label")}</b>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("License_MachineCode_Label")}
                                        name={'machineCode'}
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomInput}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("License_LicenseCode_Label")}
                                        name={'licenseCode'}
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomTextArea}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-6">
                                    <Field
                                        label={t("License_LicenseRequestId_Label")}
                                        name={'licenseRequestId'}
                                        disabled={!props.isAdmin}
                                        loading={loading}
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="col-lg-1" align="left" >
                                    {license.licenseRequestId ?
                                        (
                                            <div style={{ padding: "20px 0 0" }}><Button
                                                icon="track-changes-enable"
                                                title={t("License_LicenseRequestButtonLink_Tooltip")}
                                                onClick={clickToLicenseRequest}></Button></div>
                                        )
                                        : (<span />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                {props.isAdmin ? <div className="float-end k-form-buttons">
                    {saving ? <button
                        disabled={true}
                        className="k-button"
                    >
                        <Loader size="small" type="converging-spinner" themeColor="inverse" /> &nbsp; {t("Global_Button_Text_Saving")}
                    </button> :
                        <button
                            type={'submit'}
                            disabled={!formRenderProps.allowSubmit}
                            className="k-button"
                        >
                            <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                        </button>
                    }
                </div> : ""}
            </FormElement>)
    }, [clients, license, loading, props, saving, selectedClient, applicationsData, selectedApplication, t, clickToClientData, clickToLicenseRequest]);


    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeDialogErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeDialogErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={license}
                        key={JSON.stringify(license)}
                        render={formCallback}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
