import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomInput } from "../../components/CustomComponents";
import LicenseService from "../../services/licenses.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Edit(props) {

    const { t } = useTranslation();
    const history = useHistory();

    // Loading hooks
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const [licenseTag, setLicenseTag] = React.useState({
        "value": "",
        "tagName": "",
        "licenseId": ""
    });


    React.useEffect(() => {
        setLoading(true);
        setLicenseTag(props.licenseTag);
        setLoading(false);
    }, [props.licenseTag]);

    // Events

    const handleSubmit = React.useCallback(async (dataItem) => {

        try {

            await LicenseService.updateLicenseTag(dataItem.licenseId, dataItem.tagName, dataItem.value);
            setSaving(false);
            history.push("/Clients/" + props.client.ClientId + '/Licenses/' + dataItem.licenseId + "/Tags/" + dataItem.tagName + '/Details');

        } catch (err) {

            setVisibleError(true);
            setSaving(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });

        }
    }, [history]);

    const closeDialogErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);

    const formCallback = React.useCallback((formRenderProps) => (
        <FormElement>
            <fieldset>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("LicenseTag_Details_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4" >
                                <Field
                                    label={t("LicenseTag_TagName_Label")}
                                    name={'tagName'}
                                    loading={loading}
                                    component={CustomInput}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseTag_Value_Label")}
                                    name={'value'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            {props.isAdmin ? <div className="float-end k-form-buttons">
                {saving ? <button
                    disabled={true}
                    className="k-button"
                >
                    <Loader size="small" type="converging-spinner" themeColor="inverse" /> &nbsp; {t("Global_Button_Text_Saving")}
                </button> :
                    <button
                        type={'submit'}
                        disabled={!formRenderProps.allowSubmit}
                        className="k-button"
                    >
                        <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                    </button>
                }
            </div> : ""}
        </FormElement>), [loading, props, saving, t]);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeDialogErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeDialogErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={licenseTag}
                        key={JSON.stringify(licenseTag)}
                        render={formCallback}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
