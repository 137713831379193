import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomInput, CustomDatePickerInput, CustomTextArea } from "../../components/CustomComponents";

import LicenseRequestsService from "../../services/licenserequests.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Insert() {

    const { t } = useTranslation();
    let history = useHistory();

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const [applicationsData, setApplicationsData] = React.useState([]);
    const [selectedApplication, setSelectedApplication] = React.useState(null);

    React.useEffect(() => {

        const getDataFromApps = async () => {
            let data = await LicenseRequestsService.getListOfApplications();
            setApplicationsData(data);
        }

        getDataFromApps();

    }, []);

    const licenseRequest = {

        "contactEmail": "",
        "contactName": "",
        "appId": "",
        "companyName": "",
        "machineCode": "",
        "remarks": "",
        "creationDate": new Date(),
        "windowsOSVersion": "",
        "ipAddress": "",
        "windowsUserName": "",
        "licenseRequestId": "",
        "existLicense": false
    };

    function generateQuickGuid() {
        return Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
    }

    const handleSubmit = React.useCallback((dataItem) => {

        LicenseRequestsService.insertLicenseRequest({
            "contactEmail": dataItem.contactEmail,
            "contactName": dataItem.contactName,
            "appId": selectedApplication.appId,
            "companyName": dataItem.companyName,
            "machineCode": dataItem.machineCode,
            "remarks": dataItem.remarks,
            "creationDate": new Date(dataItem.creationDate),
            "windowsOSVersion": dataItem.windowsOSVersion,
            "ipAddress": dataItem.ipAddress,
            "windowsUserName": dataItem.windowsUserName,
            "licenseRequestId": generateQuickGuid(),
            "existLicense": dataItem.existLicense

        }).then(end => {
            history.push("/LicenseRequests/" + end.data.contract.licenseRequestId + "/Details");
        }).catch((err) => {
            setVisibleError(true);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }, [history, selectedApplication]);

    const formCallback = React.useCallback((formRenderProps) => {

        const changedSelectedAppCallback = e => {
            setSelectedApplication(e.value);
            formRenderProps.onChange("appId", { value: e.value.appId });
        };

        return (
            <FormElement>
                <fieldset>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <b>{t("LicenseRequests_Details_Label")}</b>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-4">
                                    <Field
                                        label={t("LicenseRequest_MachineCode_Label")}
                                        name={'machineCode'}
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("LicenseRequest_CreationDate_Label")}
                                        name={'creationDate'}
                                        spinners={true}
                                        format="dd/MM/yyyy"
                                        component={CustomDatePickerInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <DropDownList
                                        label={t("LicenseRequest_Application_Label")}
                                        name="application"
                                        textField="applicationName"
                                        data={applicationsData}
                                        dataItemKey="applicationId"
                                        style={{ width: '100%' }}
                                        value={selectedApplication}
                                        onChange={changedSelectedAppCallback}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <Field
                                        label={t("LicenseRequest_CompanyName_Label")}
                                        name={'companyName'}
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("LicenseRequest_ContactName_Label")}
                                        name={'contactName'}
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("LicenseRequest_ContactEmail_Label")}
                                        name={'contactEmail'}
                                        component={CustomInput}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <Field
                                        label={t("LicenseRequest_windowsOsversion_Label")}
                                        name={'windowsOSVersion'}
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("LicenseRequest_WindowsUsername_Label")}
                                        name={'windowsUserName'}
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("LicenseRequest_IPAddress_Label")}
                                        name={'ipAddress'}
                                        component={CustomInput}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-12">
                                    <Field
                                        label={t("LicenseRequest_Remarks_Label")}
                                        name={'remarks'}
                                        component={CustomTextArea}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="float-end k-form-buttons">
                    <button
                        type={'submit'}
                        disabled={!formRenderProps.allowSubmit}
                        className="k-button"
                    >
                        <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                    </button>
                </div>
            </FormElement>)
    }, [applicationsData, selectedApplication, t]);

    const closeErrorWrapper = React.useCallback(() => { setVisibleError(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorWrapper}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorWrapper}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={licenseRequest}
                        key={JSON.stringify(licenseRequest)}
                        render={formCallback} />

                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
