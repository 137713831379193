import React from 'react';

import './styleKendo.css';
import './App.css';
//import '@progress/kendo-theme-default/dist/all.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-loading-skeleton/dist/skeleton.css'

import { Route, Switch } from "react-router-dom";
import DrawerRouterContainer from './components/DrawerRouterContainer';
import Login from "./components/Login";
import Profile from "./pages/profile/List";
import LicensesCreate from "./pages/licenses/Add";
import LicensesDetails from "./pages/licenses/Details";
import LicensesList from "./pages/licenses/List";
import LicenseTagCreate from "./pages/licenses/AddTags";
import LicenseTagDetails from "./pages/licenses/DetailsTags";
import LicenseTagList from "./pages/licenses/ListTags";
import ClientsList from "./pages/clients/List";
import ClientsCreate from "./pages/clients/Add";
import ClientsDetails from "./pages/clients/Details";
import ClientContractsDetails from "./pages/clients/ListContracts";
import ClientContractDetails from "./pages/clients/DetailsContract";
import ClientContractInsert from "./pages/clients/AddContract";
import ClientLicensesDetails from "./pages/clients/ListLicenses";
import ClientLicenseDetails from "./pages/clients/DetailsLicence";
import ClientLicenseDetailTags from "./pages/clients/DetailsLicenceTags";
import ClientLicenseDetailTag from "./pages/clients/DetailsLicenceTag";
import ClientLicenseInsert from "./pages/clients/AddLicence";
import LicenseRequestsCreate from "./pages/license requests/Add";
import LicenseRequestsDetails from "./pages/license requests/Details";
import LicenseRequestsList from "./pages/license requests/List";
import LicenseTagsCreate from "./pages/licenseTags/Add";
import LicenseTagsDetails from "./pages/licenseTags/Details";
import LicenseTagsList from "./pages/licenseTags/List";
import ContractsList from "./pages/contracts/List";
import ContractsCreate from "./pages/contracts/Add";
import ContractsDetails from "./pages/contracts/Details";
import ApplicationsList from "./pages/application/List";
import ApplicationsCreate from "./pages/application/Add";
import ApplicationsDetails from "./pages/application/Details";
import ApplicationTagsList from "./pages/application/TagsList";
import ApplicationTagsCreate from "./pages/application/TagsAdd";
import ApplicationTagsDetails from "./pages/application/TagsDetails";
import HomeList from "./pages/home/List";
import version from './version';
import AuthService from "./services/auth.service";
import ContractTypeList from "./pages/contractTypes/List";
import ContractTypeCreate from "./pages/contractTypes/Add";
import ContractTypeDetails from "./pages/contractTypes/Details";

export default function App() {

    if (process.env.NODE_ENV === "production") {
        let user = AuthService.getCurrentUser();    
        if (!user)
            window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_LIC_WEBAPP&solution=AGRF_LIC');
    }

    return (
        <div>
            <DrawerRouterContainer>
                <Switch>
                    <Route exact path={["/", "/home"]} component={HomeList} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/Licenses/Create" component={LicensesCreate} />
                    <Route exact path="/Licenses/" component={LicensesList} />
                    <Route exact path="/Licenses/:licenseId/:licenseRequestId/Details" component={LicensesDetails} />
                    <Route exact path="/Licenses/:licenseId/Details" component={LicensesDetails} />
                    <Route exact path="/Licenses/:licenseId/Tags" component={LicenseTagList} />
                    <Route exact path="/Licenses/:licenseId/Tags/Add" component={LicenseTagCreate} />
                    <Route exact path="/Licenses/:licenseId/Tags/:tagName/Details" component={LicenseTagDetails} />
                    <Route exact path="/Clients/Create" component={ClientsCreate} />
                    <Route exact path="/Clients/" component={ClientsList} />
                    <Route exact path="/Clients/:clientId/Details" component={ClientsDetails} />
                    <Route exact path="/Clients/:clientId/Contracts" component={ClientContractsDetails} />
                    <Route exact path="/Clients/:clientId/Licenses" component={ClientLicensesDetails} />
                    <Route exact path="/Clients/:clientId/Licenses/Add" component={ClientLicenseInsert} />
                    <Route exact path="/Clients/:clientId/Licenses/:licenseId/Details" component={ClientLicenseDetails} />
                    <Route exact path="/Clients/:clientId/Licenses/:licenseId/Tags" component={ClientLicenseDetailTags} />
                    <Route exact path="/Clients/:clientId/Licenses/:licenseId/Tags/:tagName/Details" component={ClientLicenseDetailTag} />
                    <Route exact path="/Clients/:clientId/Contracts/Add" component={ClientContractInsert} />
                    <Route exact path="/Clients/:clientId/Contracts/:contractId/Details" component={ClientContractDetails} />
                    <Route exact path="/Contracts/" component={ContractsList} />
                    <Route exact path="/Contracts/Create" component={ContractsCreate} />
                    <Route exact path="/Contracts/:contractId/Details" component={ContractsDetails} />
                    <Route exact path="/LicenseRequests/Create" component={LicenseRequestsCreate} />
                    <Route exact path="/LicenseRequests/" component={LicenseRequestsList} />
                    <Route exact path="/LicenseRequests/:licenseRequestId/Details" component={LicenseRequestsDetails} />
                    <Route exact path="/licenses/:licenseRequestId/Create" component={LicensesCreate} />
                    <Route exact path="/licenseTags/Create" component={LicenseTagsCreate} />
                    <Route exact path="/licenseTags/" component={LicenseTagsList} />
                    <Route exact path="/licenseTags/:tagName/Details" component={LicenseTagsDetails} />
                    <Route exact path="/Applications/Create" component={ApplicationsCreate} />
                    <Route exact path="/Applications/" component={ApplicationsList} />
                    <Route exact path="/Applications/:applicationId/Details" component={ApplicationsDetails} />
                    <Route exact path="/Applications/:applicationId/Tags" component={ApplicationTagsList} />
                    <Route exact path="/Applications/:applicationId/Tags/:tagName/Details" component={ApplicationTagsDetails} />
                    <Route exact path="/Applications/:applicationId/Tags/Create" component={ApplicationTagsCreate} />
                    <Route exact path="/contractTypes/Create" component={ContractTypeCreate} />
                    <Route exact path="/contractTypes/" component={ContractTypeList} />
                    <Route exact path="/contractTypes/:contractTypeId/Details" component={ContractTypeDetails} />
                </Switch>
                <div className="text-center p-3">
                    <hr style={{ align: 'center', width: '50%', marginLeft: '25%' }} />
                    <footer> {version} © Caedis Licensing {(new Date().getFullYear())} - Agrifood Alternative Technologies, SL &amp; Caedis Integral Solutions, SL </footer>
                </div>
            </DrawerRouterContainer>

        </div>
    );
}

