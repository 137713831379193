import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomInput, CustomCheckbox, CustomDatePickerInput, CustomTextArea } from "../../components/CustomComponents"

import LicenseService from "../../services/licenses.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Insert(props) {

    const { t } = useTranslation();
    let history = useHistory();


    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const license = {
        "allowUpdates": false,
        "appId": "",
        "creationDate": new Date(),
        "licenseCode": "",
        "isDisabled": false,
        "clientId": -1,
        "licenseRequestId": "",
        "machineCode": "",
        "remarks": "",
        "maxVersion": ""
    };

    const handleSubmit = React.useCallback((dataItem) => {

        LicenseService.insertLicense({
            "clientId": props.client.clientId,
            "allowUpdates": dataItem.allowUpdates,
            "appId": dataItem.appId,
            "creationDate": new Date(dataItem.creationDate),
            "licenseCode": dataItem.licenseCode,
            "isDisabled": dataItem.isDisabled,
            "licenseRequestId": dataItem.licenseRequestId,
            "machineCode": dataItem.machineCode,
            "remarks": dataItem.remarks,
            "maxVersion": dataItem.maxVersion

        }).then(end => {
            history.push("/Clients/" + props.client.clientId + "/Licenses/" + end.data.contract.licenseId + "/Details");
        }).catch((err) => {
            setVisibleError(true);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }, [history, props]);

    const closeErrorDialogCallback = React.useCallback(() => { setVisibleError(false) }, []);
    const formCallback = React.useCallback((formRenderProps) => (
        <FormElement>
            <fieldset>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("License_Details_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-2">
                                <Field
                                    label={t("License_CreationDate_Label")}
                                    name={'creationDate'}
                                    spinners={true}
                                    format="dd/MM/yyyy"
                                    component={CustomDatePickerInput}
                                />
                            </div>
                            <div className="col-lg-2">
                                <Field
                                    label={t("License_AppId_Label")}
                                    name={'appId'}
                                    component={CustomInput}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("License_MaxVersion_Label")}
                                    name={'maxVersion'}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-2">
                                <Field
                                    label={t("License_IsDisabled_Label")}
                                    name={'isDisabled'}
                                    component={CustomCheckbox}
                                />
                            </div> <div className="col-lg-2">
                                <Field
                                    label={t("License_AllowUpdates_Label")}
                                    name={'allowUpdates'}
                                    component={CustomCheckbox}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <Field
                                    label={t("License_Remarks_Label")}
                                    name={'remarks'}
                                    component={CustomTextArea}
                                />
                            </div>

                        </div>

                    </div>
                </div>
                <br />
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("License_Details_Codes_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <Field
                                    label={t("License_MachineCode_Label")}
                                    name={'machineCode'}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Field
                                    label={t("License_LicenseCode_Label")}
                                    name={'licenseCode'}
                                    component={CustomTextArea}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Field
                                    label={t("License_LicenseRequestId_Label")}
                                    name={'licenseRequestId'}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </fieldset>
            <br />
            <div className="float-end k-form-buttons">
                <button
                    type={'submit'}
                    disabled={!formRenderProps.allowSubmit}
                    className="k-button"
                >
                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                </button>
            </div>
        </FormElement>), [t]);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorDialogCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorDialogCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={license}
                        key={JSON.stringify(license)}
                        render={formCallback}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
