import React from 'react';
import Edit from '../../models/clients/EditContract';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ClientService from "../../services/clients.service";
import ClientContractService from "../../services/clientContracts.service";
import AuthService from "../../services/auth.service";

export default function EditContract(props) {

    const { t } = useTranslation();
    const { params } = props.match;

    const [client, setClient] = React.useState({

        "clientId": 0,
        "name": "",
        "remarks": ""

    });

    const [contract, setContract] = React.useState({

        "clientId": 0,
        "contractId": 0,
        "creationDate": new Date(),
        "startDate": new Date(),
        "endDate": new Date(),
        "remarks": "",
        "description": ""

    });


    React.useEffect(() => {

        ClientService.getClient(params.clientId)
            .then(response => response.data)
            .then(json => {
                setClient({
                    "clientId": json.contract.clientId,
                    "name": json.contract.name,
                    "remarks": json.contract.remarks
                });
            });

    }, [params.clientId]);

    React.useEffect(() => {

        ClientContractService.getClientContract(params.clientId, params.contractId)
            .then(response => response.data)
            .then(json => {
                setContract({
                    "clientId": params.clientId,
                    "contractId": json.contract.contractId,
                    "startDate": Date.parse(json.contract.startDate),
                    "creationDate": Date.parse(json.contract.creationDate),
                    "endDate": Date.parse(json.contract.endDate),
                    "remarks": json.contract.remarks ?? "",
                    "isDisabled": json.contract.isDisabled,
                    "description": json.contract.description
                });

            });

    }, [params.clientId, params.contractId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/clients/'}>{t("Clients_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/clients/' + client.clientId + '/Details'}>{client.name}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/clients/' + client.clientId + '/Contracts'}>{t("Clients_Contracts_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/clients/' + client.clientId + '/Contracts/'+ params.contractId +'/Details'}>{params.contractId}</Link></li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Clients/' + params.clientId + '/Details/'} className="nav-link">{t("Clients_Details_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Clients/' + params.clientId + '/Contracts'} className="nav-link active">{t("Clients_Contracts_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Clients/' + params.clientId + '/Licenses'} className="nav-link">{t("Clients_Licenses_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Edit
                        isAdmin={AuthService.isUserAdmin()}
                        client={client}
                        contract={contract}
                        contractId={params.contractId}
                        clientId={params.clientId}
                    />
                </div>
            </div>
            <br />
            <br />
        </div >
    );

}
