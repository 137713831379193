import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import { toODataString } from '@progress/kendo-data-query';
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import LicencesService from "../../services/licenses.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function List(props) {

    const { t } = useTranslation();

    // Grud Hooks
    const [sortState, setSortState] = React.useState([]);
    const [pageState, setPageState] = React.useState({ skip: 0, take: 10 });
    const [total, setTotal] = React.useState(0);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Data Hooks
    const [licencesData, setLicencesData] = React.useState([]);

    // Delete Event Hooks
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [dataItemToRemove, setDataItemToRemove] = React.useState({});

    // Loading Hooks
    const [loading, setLoading] = React.useState(true);

    // Filter Hooks
    const [orderByString, setOrderByString] = React.useState("");


    // Aspect Functions

    const CommandCell = React.useCallback(cellProps => (
        <CustomListCommandCellWithRemove
            {...cellProps}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/Clients/' + props.client.clientId + '/Licenses/' + cellProps.dataItem.licenseId + '/Details'}
            isAdmin={props.isAdmin}

        />
    ), [props]);

    const AllowUpdatesCommandCell = React.useCallback(cellProps => (
        <td className="k-command-cell" style={{ textAlign: "center" }}>
            {
                (cellProps.dataItem.allowUpdates === true) ? <span className='k-icon k-i-cloud' title={t("Licenses_AllowUpdates_TooltipText")} />
                    : <span className='k-icon k-i-close' title={t("Licenses_NotAllowUpdates_TooltipText")} />
            }

        </td>
    ), [t]);

    const IsDisabledCommandCell = React.useCallback(cellProps => (

        <td className="k-command-cell" style={{ textAlign: "center" }}>
            {
                (cellProps.dataItem.isDisabled === true) ? <span className='k-icon k-i-check' title={t("Licenses_Enabled_TooltipText")} />
                    : <span className='k-icon k-i-close' title={t("Licenses_Disabled_TooltipText")} />
            }

        </td>
    ), [t]);

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const MachineCodeTruncateCommandCell = React.useCallback(cellProps => (
        <td className="k-command-cell">
            {
                (cellProps.dataItem.machineCode === null) ? "" : cellProps.dataItem.machineCode.slice(0, 20) + "..."
            }
        </td>
    ), []);

    const LicenseCodeTruncateCommandCell = React.useCallback(cellProps => (
        <td className="k-command-cell">
            {
                cellProps.dataItem.licenseCode.slice(0, 20) + "..."
            }

        </td>
    ), []);

    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    }, []);

    const remove = (dataItem) => {

        setDataItemToRemove(dataItem);
        setVisibleRemove(true);

    }

    const removeDataItem = () => {

        setLoading(true);
        LicencesService.deleteLicense(dataItemToRemove.licenseId).then(() => {
            loadData();
            setDataItemToRemove({});
            setVisibleRemove(false);
        }).catch((err) => {
            setDataItemToRemove({});
            setVisibleError(true);
            setVisibleRemove(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }



    const loadData = React.useCallback((filter = "", orderByFilter = "") => {

        LicencesService.getLicensesFiltered(filter, orderByFilter, Math.ceil((pageState.skip + 1) / pageState.take), pageState.take)
            .then(response => response.data)
            .then(json => {
                json.contract.map((item) => {
                    let newDate = new Date(item.creationDate);
                    newDate.setHours(0, 0, 0, 0);
                    item.creationDate = newDate;
                    if (item.isDisabled === false) {
                        item.isDisabled = true;
                    }
                    else {
                        item.isDisabled = false;
                    }
                    return item;
                })
                setLicencesData(json.contract);
                setTotal(json.totalResults);
                setLoading(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });
    }, [pageState]);

    const dataFiltered = React.useCallback(() => {

        setLoading(true);

        let filter = "";

        filter += "ClientId eq " + props.clientId;

        let orderByFilter = "";

        if (orderByString === "")
            orderByFilter = "?orderBy=CreationDate";
        else
            orderByFilter = orderByString;

        loadData(filter, orderByFilter);

    }, [loadData, orderByString, props]);



    // Effects

    React.useEffect(() => {

        if (props.clientId > 0) {
            loadData("ClientId eq " + props.clientId);
        }

    }, [props, loadData]);


    const sortChange = React.useCallback((event) => {
        let oDataString = toODataString({ sort: event.sort });
        oDataString = oDataString.replace("name", "Name");
        oDataString = oDataString.replace("clientId", "ClientId");
        oDataString = oDataString.replace("creationDate", "CreationDate");
        oDataString = oDataString.replace("$orderby", "?orderBy");
        setOrderByString(oDataString);
        setSortState(event.sort);
        dataFiltered();
    }, [dataFiltered]);

    const pageChange = React.useCallback((event) => {
        setPageState(prev => ({ ...prev, skip: event.page.skip, take: event.page.take }));
    }, []);

    const closeErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);
    const closeRemoveCallback = React.useCallback(() => { setVisibleRemove(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {visibleRemove && (
                <Dialog title={t("Global_RemoveDialog_Title")} onClose={closeRemoveCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{t("Global_RemoveDialog_Text") + " \"" + dataItemToRemove.licenseId + "\" ?"} </b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => removeDataItem()}>
                            {t("Global_OKButton_Text")}
                        </button>
                        <button className="k-button" onClick={closeRemoveCallback}>
                            {t("Global_CancelButton_Text")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    {loading && loadingPanel}
                    <Grid
                        editField='inEdit'
                        cellRender={loadingCell}
                        pageable={{
                            pageSizes: [10, 20, 50, 100],
                        }}
                        data={licencesData}
                        total={total}
                        skip={pageState.skip}
                        take={pageState.take}
                        scrollable={'none'}
                        sortable={{
                            allowUnsort: true,
                            mode: "single"
                        }}
                        sort={sortState}
                        onSortChange={sortChange}
                        onPageChange={pageChange}

                    >
                        {props.isAdmin ? <GridToolbar>
                            <div>
                                <Link to={'/Clients/' + props.client.clientId + '/Licenses/Add'}>
                                    <button title={t("Licenses_Button_Tooltip_Create")} className="k-button k-primary">
                                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                                    </button>
                                </Link>
                            </div>
                        </GridToolbar> : ""}
                        <Column cell={CommandCell} width={props.isAdmin ? "130px" : "80px"} sortable={false} />
                        <Column cell={AllowUpdatesCommandCell} sortable={false} />
                        <Column cell={IsDisabledCommandCell} field="isDisabled" title={" "} sortable={true} />
                        <Column field="creationDate" title={t("Licenses_CreationDate_Label")} format="{0:d}" sorteable={true} />
                        <Column field="appId" title={t("Licenses_AppId_Label")} width="150px" />
                        <Column cell={MachineCodeTruncateCommandCell} title={t("Licenses_MachineKey_Label")} />
                        <Column cell={LicenseCodeTruncateCommandCell} title={t("Licenses_LicenseCode_Label")} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}

