import * as React from 'react';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomRedirectListCommandCell } from "../../components/GridCustomRedirectListCommandCell";
import { CustomInput, CustomCheckbox, CustomTextArea, CustomDatePickerInput } from "../../components/CustomComponents"

import ClientContractsService from "../../services/clientContracts.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Edit(props) {

    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    const [clientContract, setClientContract] = React.useState({
        "contractId": 0,
        "creationDate": new Date(),
        "startDate": new Date(),
        "endDate": new Date(),
        "remarks": "",
        "description": ""
    });

    const [clientContractEdit, setClientContractEdit] = React.useState({
        "contractId": 0,
        "creationDate": new Date(),
        "startDate": new Date(),
        "endDate": new Date(),
        "remarks": "",
        "description": ""
    });

    const [contractLicences, setContractLicences] = React.useState([]);
    const [notContractLicences, setNotContractLicences] = React.useState([]);

    const [licensesToDelete, setLicensesToDelete] = React.useState([]);
    const [selectedLicense, setSelectedLicense] = React.useState(null);
    const [licensesToAdd, setLicensesToAdd] = React.useState([]);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const loadData = () => {

        if (props.contract.contractId !== 0) {

            let contractData = props.contract;

            loadLicenses();

            let dataFor = {
                "contractId": contractData.contractId,
                "creationDate": new Date(contractData.creationDate),
                "endDate": new Date(contractData.endDate),
                "startDate": new Date(contractData.startDate),
                "remarks": contractData.remarks,
                "isDisabled": contractData.isDisabled,
                "description": contractData.description
            };

            setClientContract(dataFor);
            setClientContractEdit(dataFor);
            setLoading(false);
        }

    }

    const loadLicenses = React.useCallback(() => {
        ClientContractsService.getClientContractLicenses(props.clientId, props.contract.contractId)
            .then(json => {
                let licencesInContract = [];
                let licencesNotInContract = [];
                if (json.data.contract)
                    for (let item of json.data.contract) {

                        item.description = '(' + item.licenseId + ') ' + item.appId

                        if (item.inContract)
                            licencesInContract.push(item);
                        else
                            licencesNotInContract.push(item);
                    }
                setContractLicences(licencesInContract);
                setNotContractLicences(licencesNotInContract);
                setLicensesToAdd([]);
                setLicensesToDelete([]);
            });
    }, [props]);

    React.useEffect(loadData, [props.contract, loadLicenses]);

    const handleSubmit = React.useCallback(async () => {

        try {
            setSaving(true);

            let returnData = await ClientContractsService.updateClientContract(props.clientId, props.contractId, {           
                "creationDate": clientContractEdit.creationDate,
                "endDate": clientContractEdit.endDate,
                "startDate": clientContractEdit.startDate,
                "remarks": clientContractEdit.remarks,
                "isDisabled": clientContractEdit.isDisabled,
                "description": clientContractEdit.description

            });

            let dataFor = {
                "contractId": returnData.data.contract.contractId,
                "creationDate": new Date(returnData.data.contract.creationDate),
                "endDate": new Date(returnData.data.contract.endDate),
                "startDate": new Date(returnData.data.contract.startDate),
                "remarks": returnData.data.contract.remarks,
                "isDisabled": returnData.data.contract.isDisabled,
                "description": returnData.data.contract.description
            };

            setClientContractEdit(dataFor);
            setClientContract(dataFor);
            setLoading(false);

            for (let licToAdd of licensesToAdd)
                await ClientContractsService.saveClientContractLicense(props.clientId, props.contractId, licToAdd.licenseId);

            for (let licToDelete of licensesToDelete)
                await ClientContractsService.deleteClientContractLicense(props.clientId, props.contractId, licToDelete.licenseId);

            loadLicenses();
            setSaving(false);
        } catch (err) {
            setSaving(false);
            setVisibleError(true);
            setLoading(false);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        }

    }, [clientContractEdit, licensesToAdd, licensesToDelete, props, loadLicenses]);

    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    }, []);

    const removeLicense = React.useCallback((dataItem) => {

        setContractLicences(contractLicences.filter(y => y.licenseId !== dataItem.licenseId));
        setNotContractLicences([...notContractLicences, dataItem]);
        setLicensesToAdd(licensesToAdd.filter(y => y.licenseId !== dataItem.licenseId));
        setLicensesToDelete([...licensesToDelete, dataItem]);
        setSelectedLicense(null);

    }, [licensesToAdd, licensesToDelete, contractLicences, notContractLicences]);

    const CommandCell = React.useCallback(cellProps => (
        <CustomRedirectListCommandCell
            {...cellProps}
            editField='inEdit'
            linkToDetails={'/licenses/' + cellProps.dataItem.licenseId + '/""/Details'}
            remove={removeLicense}
            isAdmin={props.isAdmin}

        />
    ), [props, removeLicense]);

    const addLicense = React.useCallback(() => {

        if (selectedLicense !== null) {
            setNotContractLicences(notContractLicences.filter(y => y.licenseId !== selectedLicense.licenseId));
            setContractLicences([...contractLicences, selectedLicense]);
            setLicensesToAdd([...licensesToAdd, selectedLicense]);
            setLicensesToDelete(licensesToDelete.filter(y => y.licenseId !== selectedLicense.licenseId));
            setSelectedLicense(null);
        }
    }, [licensesToAdd, licensesToDelete, notContractLicences, contractLicences, selectedLicense]);


    const editDescription = React.useCallback(e => {
        setClientContractEdit({ ...clientContractEdit, description: e.value });
    }, [clientContractEdit])

    const editIsDisabled = React.useCallback(e => {
        setClientContractEdit({ ...clientContractEdit, isDisabled: e.value });
    }, [clientContractEdit]);

    const editCreationDate = React.useCallback(e => {
        setClientContractEdit({ ...clientContractEdit, creationDate: e.value });
    }, [clientContractEdit]);

    const editEndDate = React.useCallback(e => {
        setClientContractEdit({ ...clientContractEdit, endDate: e.value });
    }, [clientContractEdit]);

    const editStartDate = React.useCallback(e => {
        setClientContractEdit({ ...clientContractEdit, startDate: e.value });
    }, [clientContractEdit]);

    const editRemarks = React.useCallback(e => {
        setClientContractEdit({ ...clientContractEdit, remarks: e.value });
    }, [clientContractEdit]);

    const editNotContractLicenses = React.useCallback(e => {
        let obt = notContractLicences.find(x => x.licenseId === e.value.licenseId);
        if (obt)
            setSelectedLicense(obt);
    }, [notContractLicences]);

    const formCallback = React.useCallback(() => (
        <FormElement>
            <fieldset>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("Client_Contracts_Details_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-2">
                                <Field
                                    label={t("Client_Contract_ContractId_Label")}
                                    name={'contractId'}
                                    loading={loading}
                                    component={CustomInput}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Field
                                    label={t("Client_Contract_Description_Label")}
                                    name={'description'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomInput}
                                    onChange={editDescription}
                                />
                            </div>
                            <div className="col-sm-2">
                                <Field
                                    label={t("Client_Contract_IsDisabled_Label")}
                                    name={'isDisabled'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomCheckbox}
                                    onChange={editIsDisabled}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-4">
                                <Field
                                    label={t("Client_Contract_CreationDate_Label")}
                                    name={'creationDate'}
                                    disabled={!props.isAdmin}
                                    loading={loading}
                                    format={"dd MMMM yyyy"}
                                    component={CustomDatePickerInput}
                                    onChange={editCreationDate}
                                />
                            </div>
                            <div className="col-sm-4">
                                <Field
                                    label={t("Client_Contract_StartDate_Label")}
                                    name={'startDate'}
                                    disabled={!props.isAdmin}
                                    loading={loading}
                                    format={"dd MMMM yyyy"}
                                    component={CustomDatePickerInput}
                                    onChange={editStartDate}
                                />
                            </div>
                            <div className="col-sm-4">
                                <Field
                                    label={t("Client_Contract_EndDate_Label")}
                                    name={'endDate'}
                                    disabled={!props.isAdmin}
                                    loading={loading}
                                    format={"dd MMMM yyyy"}
                                    component={CustomDatePickerInput}
                                    onChange={editEndDate}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <Field
                                    label={t("Client_Contract_Remarks_Label")}
                                    name={'remarks'}
                                    component={CustomTextArea}
                                    onChange={editRemarks}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <br />
            {props.isAdmin ? <div className="row">
                <div className="col-sm-4">
                    <DropDownList
                        textField={'description'}
                        data={notContractLicences}
                        onChange={editNotContractLicenses}
                    />
                </div>
                <div className="col-sm-1">
                    <Button
                        onClick={addLicense}
                        disabled={selectedLicense === null}
                    >{t("Global_Button_Text_Add")}</Button>
                </div>
            </div> : ""}
            <br />
            <Grid
                editField='inEdit'
                cellRender={loadingCell}
                data={contractLicences}
                total={contractLicences.length}
                scrollable={'none'}

            >
                {props.isAdmin ? <Column cell={CommandCell} width={"80px"} sortable={false} /> : ""}
                <Column field="licenseId" title={t("Licenses_LicenseId_Label")} />
                <Column field="appId" title={t("Licenses_AppId_Label")} />

            </Grid>
            {props.isAdmin ? <div className="float-end k-form-buttons">
                {saving ?
                    <button
                        type={'submit'}
                        disabled={true}
                        className="k-button">
                        <Loader size="small" type="converging-spinner" themeColor="inverse" /> &nbsp; {t("Global_Button_Text_Saving")}
                    </button>
                    :
                    <button
                        onClick={handleSubmit}
                        className="k-button"
                    >
                        <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                    </button>}
            </div> : ""}
        </FormElement>), [CommandCell, addLicense, editEndDate, editCreationDate,
        editStartDate, props, t, notContractLicences, loading, loadingCell,
        editDescription, editIsDisabled, editRemarks, contractLicences, handleSubmit,
        saving, selectedLicense, editNotContractLicenses]);

    const closeErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={clientContract}
                    key={JSON.stringify(clientContract)}
                    render={formCallback}
                />
            </LocalizationProvider>
        </div>
    );
}
