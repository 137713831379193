import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';

import { CustomInput, CustomCheckbox, CustomDatePickerInput, CustomTextArea } from "../../components/CustomComponents";
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import LicenseService from "../../services/licenses.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function EditLicense(props) {

    const { t } = useTranslation();
    const history = useHistory();

    // Loading hooks
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Hooks
    const [license, setLicense] = React.useState({
        "allowUpdates": false,
        "appId": "",
        "creationDate": Date.now.toJSON,
        "licenseCode": "",
        "licenseId": -1,
        "isDisabled": false,
        "clientId": -1,
        "licenseRequestId": "",
        "machineCode": "",
        "remarks": "",
        "maxVersion": ""
    });

    React.useEffect(() => {


        const func = async () => {

            let license = await LicenseService.getLicenseById(props.licenseId);

            setLicense({
                "allowUpdates": license.data.contract.allowUpdates,
                "appId": license.data.contract.appId,
                "creationDate": new Date(license.data.contract.creationDate),
                "licenseCode": license.data.contract.licenseCode,
                "licenseId": license.data.contract.licenseId,
                "isDisabled": license.data.contract.isDisabled,
                "clientId": license.data.contract.clientId,
                "licenseRequestId": license.data.contract.licenseRequestId,
                "machineCode": license.data.contract.machineCode,
                "remarks": license.data.contract.remarks,
                "maxVersion": license.data.contract.maxVersion

            });

            setLoading(false);
        }

        func();

    }, [props.licenseId]);

    // Events

    const handleSubmit = React.useCallback(async (dataItem) => {

        try {
            await LicenseService.updateLicense(license.licenseId, {
                "allowUpdates": dataItem.allowUpdates,
                "appId": dataItem.appId,
                "creationDate": new Date(dataItem.creationDate),
                "licenseCode": dataItem.licenseCode,
                "licenseId": license.licenseId,
                "isDisabled": dataItem.isDisabled,
                "clientId": props.client.clientId,
                "licenseRequestId": dataItem.licenseRequestId,
                "machineCode": dataItem.machineCode,
                "remarks": dataItem.remarks,
                "maxVersion": dataItem.maxVersion

            });

            setSaving(false);
            history.push("/Clients/" + props.client.clientId + "/Licenses/" + license.licenseId + "/Details");

        } catch (err) {

            setVisibleError(true);
            setSaving(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });

        }
    }, [history, license, props]);

    const closeErrorDialogCallback = React.useCallback(() => { setVisibleError(false) }, []);
    const formCallback = React.useCallback((formRenderProps) => (
        <FormElement>
            <fieldset>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("License_Details_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4" >
                                <Field
                                    label={t("License_LicenseId_Label")}
                                    name={'licenseId'}
                                    loading={loading}
                                    component={CustomInput}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-lg-2">
                                <Field
                                    label={t("License_CreationDate_Label")}
                                    name={'creationDate'}
                                    spinners={true}
                                    format="dd/MM/yyyy"
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomDatePickerInput}
                                />
                            </div>
                            <div className="col-lg-2">
                                <Field
                                    label={t("License_AppId_Label")}
                                    name={'appId'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomInput}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("License_MaxVersion_Label")}
                                    name={'maxVersion'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-2">
                                <Field
                                    label={t("License_IsDisabled_Label")}
                                    name={'isDisabled'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomCheckbox}
                                />
                            </div>
                            <div className="col-lg-3">
                                <Field
                                    label={t("License_AllowUpdates_Label")}
                                    name={'allowUpdates'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomCheckbox}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <Field
                                    label={t("License_Remarks_Label")}
                                    name={'remarks'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomTextArea}
                                />
                            </div>

                        </div>
                        <div className="row">

                        </div>
                    </div>
                </div>
                <br />
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("License_Details_Codes_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <Field
                                    label={t("License_MachineCode_Label")}
                                    name={'machineCode'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomInput}
                                />
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <Field
                                    label={t("License_LicenseCode_Label")}
                                    name={'licenseCode'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomTextArea}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <Field
                                    label={t("License_LicenseRequestId_Label")}
                                    name={'licenseRequestId'}
                                    disabled={!props.isAdmin}
                                    loading={loading}
                                    component={CustomInput}
                                />
                            </div>
                            <div className="col-lg-1" >
                                {license.licenseRequestId ?
                                    (
                                        <Link style={{ padding: "40px 0 0" }} to={'/LicenseRequests/' + license.licenseRequestId + '/Details'} className='k-icon k-i-link'></Link>
                                    )
                                    : (<span />)}
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <br />
            {props.isAdmin ? <div className="float-end k-form-buttons">
                {saving ? <button
                    disabled={true}
                    className="k-button"
                >
                    <Loader size="small" type="converging-spinner" themeColor="inverse" /> &nbsp; {t("Global_Button_Text_Saving")}
                </button> :
                    <button
                        type={'submit'}
                        disabled={!formRenderProps.allowSubmit}
                        className="k-button"
                    >
                        <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                    </button>
                }
            </div> : ""}
        </FormElement>), [license, loading, props, saving, t]);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorDialogCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorDialogCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={license}
                        key={JSON.stringify(license)}
                        render={formCallback}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
