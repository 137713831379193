import React from 'react';
import Edit from '../../models/clients/ListContracts';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ClientService from "../../services/clients.service";
import AuthService from "../../services/auth.service";

export default function EditLicenses(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [client, setClient] = React.useState({

        "clientId": 0,
        "name": "",
        "remarks": ""

    });

    React.useEffect(() => {

        ClientService.getClient(params.clientId)
            .then(response => response.data)
            .then(json => {
                setClient({

                    "clientId": json.contract.clientId,
                    "name": json.contract.name,
                    "remarks": json.contract.remarks

                });

            });
    }, [params.clientId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/clients/'}>{t("Clients_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/clients/' + client.clientId + '/Details'}>{client.name}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/clients/' + client.clientId + '/Contracts'}>{t("Clients_Contracts_Breadcrumb_Title")}</Link></li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Clients/' + params.clientId + '/Details/'} className="nav-link">{t("Clients_Details_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Clients/' + params.clientId + '/Contracts'} className="nav-link active">{t("Clients_Contracts_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Clients/' + params.clientId + '/Licenses'} className="nav-link">{t("Clients_Licenses_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Edit
                        isAdmin={AuthService.isUserAdmin()}
                        client={client}
                        clientId={params.clientId}
                    />
                </div>
            </div>
            <br />
            <br />
        </div >
    );

}
