import React from 'react';
import Edit from '../../models/licenses/EditTags';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LicenseService from "../../services/licenses.service";
import AuthService from "../../services/auth.service";

export default function EditLicenses(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [licenseTag, setLicenseTag] = React.useState({

        "licenseId": -1,
        "value": "",
        "tagName": ""

    });

    React.useEffect(() => {


        LicenseService.getLicenseTagByTagName(params.licenseId, params.tagName)
            .then(response => response.data)
            .then(json => {
                setLicenseTag({
                    "value": json.contract.value,
                    "tagName": params.tagName,
                    "licenseId": params.licenseId,
                });

            });


    }, [params]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenses/'}>{t("Licenses_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenses/' + params.licenseId + '/Details'}>{params.licenseId}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenses/' + params.licenseId + '/Tags'}>{t("Licenses_Tags_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenses/' + params.licenseId + '/Tags/' + params.tagName + '/Details'}>{params.tagName}</Link></li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Licenses/' + params.licenseId + '/Details/'} className="nav-link">{t("Licenses_Details_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Licenses/' + params.licenseId + '/Tags'} className="nav-link active">{t("Licenses_Tags_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Edit
                        licenseTag={licenseTag}
                        isAdmin={AuthService.isUserAdmin()}
                    />
                </div>
            </div>
        </div >
    );

}
