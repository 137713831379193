import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const baseUrl="/";
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Suspense fallback="loading">
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </Suspense>,
    rootElement);

registerServiceWorker();
