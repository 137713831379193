import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class ClientsService {

    getClients() {
        return axiosApiInstanceForValidation.get(API_URL + '/V1/Clients', { headers: authHeader() });
    }

    getClientsFiltered(filter, orderBy, page, pageSize) {

        let filterToSend = "";

        if (orderBy)
            filterToSend = orderBy;


        if (page)
            if (filterToSend !== "")
                filterToSend += "&page=" + page;
            else
                filterToSend = "?page=" + page;

        if (pageSize)
            if (filterToSend !== "")
                filterToSend += "&pageSize=" + pageSize;
            else
                filterToSend = "?pageSize=" + pageSize;

        if (filter)
            if (filterToSend !== "")
                filterToSend += "&filter=" + filter;
            else
                filterToSend = "?filter=" + filter;

        return axiosApiInstanceForValidation.get(API_URL + '/V3/Clients' + filterToSend, { headers: authHeader() });
    }
   
    getClient(ClientId) {
        return axiosApiInstanceForValidation.get(API_URL + '/V3/Clients/' + ClientId, { headers: authHeader() });
    }

    deleteClient(ClientId) {
        return axiosApiInstanceForValidation.delete(API_URL + '/V3/Clients/' + ClientId, { headers: authHeader() });
    }

    updateClient(ClientId, Client) {
        return axiosApiInstanceForValidation.put(API_URL + '/V3/Clients/' + ClientId, Client, { headers: authHeader() });
    }

    insertClient(Client) {
        return axiosApiInstanceForValidation.post(API_URL + '/V3/Clients', Client, { headers: authHeader() });
    }
}

export default new ClientsService();
