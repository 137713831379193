import React from 'react';
import List from '../../models/licenses/ListTags';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";

export default function Listlicenses(props) {

    const { t } = useTranslation();
    const { params } = props.match;

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenses/'}>{t("Licenses_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Licenses/' + params.licenseId + '/Details/'}>{params.licenseId}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Licenses/' + params.licenseId + '/Tags/'}>{t("Licenses_Tags_Title")}</Link></li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Licenses/' + params.licenseId + '/Details/'} className="nav-link">{t("Licenses_Details_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Licenses/' + params.licenseId + '/Tags'} className="nav-link active">{t("Licenses_Tags_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <List
                        isAdmin={AuthService.isUserAdmin()}
                        licenseId={params.licenseId}
                    />
                </div>
            </div>
        </div >
    );
}
