import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export const CustomListCommandCellThreeButtons = props => {
    const { dataItem } = props;
    const { t } = useTranslation();

    return (<td className="k-command-cell">
         <div style={{width: props.isAdmin ?  "200px" : "180px"}}>
        <Link to={props.linkToDetails}>
            <button className="k-button k-button-icontext"  style={{ marginLeft: "5px", marginRight: "10px" }}>
                &nbsp;&nbsp;<span className=" k-icon k-i-pencil" title={t("Global_Edit_Text")}></span>&nbsp;
            </button>
        </Link>
        {props.isAdmin ? (
            <button  style={{ marginRight: "10px" }} className="k-button k-grid-remove-command" onClick={() => {props.remove(dataItem)}} >
                &nbsp;&nbsp;<span className=" k-icon k-i-delete" title={t("Global_Delete_Text")}></span>&nbsp;&nbsp;
            </button>
        ) : (<span />)}

        {props.hasLicense===true ? (
        <Link to={props.linkToLicense}>
            <button className="k-button k-button-icontext">
                &nbsp;&nbsp;<span className=" k-icon k-i-hyperlink-open" title={t("LicenseRequest_EditLicense_Text")}></span>&nbsp;
            </button>
        </Link>): (<span />)}
        
        {props.hasLicense===false ? (
        <Link to={props.linkToNewLicense}>
            <button className="k-button k-button-icontext">
                &nbsp;&nbsp;<span className="k-icon k-i-plus-outline" title={t("LicenseRequest_AddLicense_Text")}></span>&nbsp;
            </button>
        </Link>): (<span />)}
        </div>
    </td>
    );
};
