import React from 'react';
import Edit from '../../models/licenseTags/Edit';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LicenseTagsService from "../../services/licenseTags.service";
import AuthService from "../../services/auth.service";

export default function EditLicenseTags(props) {
    
    const { t } = useTranslation();
    const { params } = props.match;
    const [licenseTag, setLicenseTag] = React.useState({
        "applicationId" : -1,
        "tagName" : "",
        "remarks" : "",
        "existLicense" : false
             
    });

    React.useEffect(() => {

        LicenseTagsService.getLicenseTagByTagName(params.tagName)
            .then(response => response.data)
            .then(json => {
                setLicenseTag({
                    "applicationId": json.contract.applicationId,
                    "tagName" : json.contract.tagName,
                    "remarks" : json.contract.remarks,
                    "isDisabled" :json.contract.isDisabled
                  
                });

            });
    }, [params.tagName]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenseTags/'}>{t("LicenseTags_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenseTags/' + licenseTag.tagName + '/Details'}>{licenseTag.tagName}</Link></li>
                </ol>
            </nav>
            <br />
                <Edit
                    isAdmin={AuthService.isUserAdmin()}
                    licenseTag={licenseTag}
                />
           <br />
            <br />
        </div >
    );

}
