import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomInput, CustomTextArea, CustomCheckbox } from "../../components/CustomComponents"

import ClientsService from "../../services/clients.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Insert() {

    const { t } = useTranslation();
    let history = useHistory();

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const client = {
        "clientId": 0,
        "name": "",
        "remarks": "",
        "isDisabled": false
    };

    const requiredValidator = (value) => {
        return value ? "" : t("Validator_FieldIsRequired_Text");
    }

    const handleSubmit = (dataItem) => {

        ClientsService.insertClient({
            "clientId": dataItem.clientId,
            "name": dataItem.name,
            "remarks": dataItem.remarks,
            "isDisabled": dataItem.isDisabled
        }).then(end => {
            history.push("/clients/" + end.data.contract.clientId + "/Details");
        }).catch((err) => {
            setVisibleError(true);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={client}
                    key={JSON.stringify(client)}
                    render={(formRenderProps) => (
                        <FormElement>
                            <fieldset>
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <b>{t("Client_Details_Label")}</b>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <Field
                                                    label={t("Client_Name_Label")}
                                                    name={'name'}
                                                    component={CustomInput}
                                                    validator={[requiredValidator]}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <Field
                                                    label={t("Client_IsDisabled_Label")}
                                                    name={'isDisabled'}
                                                    component={CustomCheckbox}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <Field
                                                    label={t("Client_Remarks_Label")}
                                                    name={'remarks'}
                                                    component={CustomTextArea}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <br />
                            <div className="float-end k-form-buttons">
                                <button
                                    type={'submit'}
                                    disabled={!formRenderProps.allowSubmit}
                                    className="k-button"
                                >
                                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                                </button>
                            </div>
                        </FormElement>)}
                />
            </LocalizationProvider>
        </div>
    );
}
