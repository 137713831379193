import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { CustomInput } from "../../components/CustomComponents"
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import LicenseTagService from "../../services/licenses.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Insert(props) {

    const { t } = useTranslation();
    let history = useHistory();


    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Hooks
    const [tags, setTags] = React.useState([]);
    const [selectedClient, setSelectedClient] = React.useState("");

    const licenseTag = {
        "tagName": "",
        "value": ""
    };


    React.useEffect(() => {
        LicenseTagService.getLicenseTagsFiltered(props.licenseId)
            .then(response => response.data)
            .then(json => {
                let filteredList = json.contract.filter(y => y.hasValue === false && y.isDisabled === false);
                setTags(filteredList);
            });

    }, [props]);

    const handleSubmit = React.useCallback((dataItem) => {

        LicenseTagService.insertLicenseTag(props.licenseId, selectedClient.tagName, dataItem.value).then(() => {
            history.push("/licenses/" + props.licenseId + "/Tags/" + selectedClient.tagName + '/Details');
        }).catch((err) => {
            setVisibleError(true);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }, [history, props, selectedClient]);

    const closeDialogErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);

    const clientChanged = React.useCallback(e => {
        setSelectedClient(e.value);
        //formRenderProps.onChange("tagName", { value: e.value })
    }, []);

    const formCallback = React.useCallback((formRenderProps) => (
        <FormElement>
            <fieldset>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("LicenseTags_Details_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4">
                                <DropDownList
                                    label={t("LicenseTag_TagName_Label")}
                                    name="tagName"
                                    textField="tagName"
                                    data={tags}
                                    dataItemKey="tagName"
                                    style={{ width: '100%' }}
                                    value={selectedClient}
                                    onChange={clientChanged}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseTags_Value_Label")}
                                    name={'value'}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div className="float-end k-form-buttons">
                <button
                    type={'submit'}
                    disabled={!formRenderProps.allowSubmit}
                    className="k-button"
                >
                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                </button>
            </div>
        </FormElement>), [clientChanged, t, selectedClient, tags]);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeDialogErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeDialogErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={licenseTag}
                        key={JSON.stringify(licenseTag)}
                        render={formCallback}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
