import React from 'react';
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomInput } from "../../components/CustomComponents"
import jwt_decode from "jwt-decode";
import AuthService from "../../services/auth.service";

export default function Listlicenses() {

    const { t } = useTranslation();

    const proceessComponent = (proceessComponentProps) => {
        let listOfComponents = [];

        if (proceessComponentProps.includes("AGRF_LIC_ADMINCMP"))
            listOfComponents.push("AGRF_LIC_ADMINCMP");
        return listOfComponents.join(", ");

    }

    let dataFromProfile = {

        username: jwt_decode(AuthService.getCurrentUser()).username,
        userID: jwt_decode(AuthService.getCurrentUser()).userid,
        solutionCode: jwt_decode(AuthService.getCurrentUser()).solutioncode,
        applicationCode: jwt_decode(AuthService.getCurrentUser()).applicationcode,
        componentsCodes: proceessComponent(jwt_decode(AuthService.getCurrentUser()).components)

    };


    return (

        <div>
            <br />
            {AuthService.isLogged ?
                <Form
                    initialValues={dataFromProfile}
                    key={JSON.stringify(dataFromProfile)}
                    render={() => (
                        <FormElement>
                            <fieldset>
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <b>{t("Profile_Details_Label")}</b>
                                    </div>
                                    <div className="card-body">
                                        <div className="container">

                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <Field
                                                        label={t("global_userID_label")}
                                                        name={'userID'}
                                                        component={CustomInput}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-sm-10">
                                                    <Field
                                                        label={t("global_username_label")}
                                                        name={'username'}
                                                        disabled={true}
                                                        component={CustomInput}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <b>{t("Profile_CASInfo_Label")}</b>
                                    </div>
                                    <div className="card-body">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <Field
                                                        label={t("global_solutioncode_label")}
                                                        name={'solutionCode'}
                                                        component={CustomInput}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-sm-8">
                                                    <Field
                                                        label={t("global_applicationcode_label")}
                                                        name={'applicationCode'}
                                                        disabled={true}
                                                        component={CustomInput}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <Field
                                                        label={t("global_components_label")}
                                                        name={'componentsCodes'}
                                                        component={CustomInput}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </FormElement>)
                    }
                /> : ""}
        </div >
    );
}
