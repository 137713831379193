import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export const CustomListCommandCellWithRemove = props => {
    const { dataItem } = props;
    const { t } = useTranslation();

    return (<td className="k-command-cell">
        <div style={{ width: props.isAdmin ? "130px" : "80px" }}>
            {props.linkToDetails ? <Link to={props.linkToDetails}>
                <button className="k-button k-button-icontext" style={{ marginLeft: "5px", marginRight: "10px" }}>
                    &nbsp;&nbsp;<span className=" k-icon k-i-pencil" title={t("Global_Edit_Text")}></span>&nbsp;
                </button>
            </Link> : ""}
            {props.isAdmin ? (
                <button className="k-button k-grid-remove-command" onClick={() => {props.remove(dataItem)}} >
                    &nbsp;&nbsp;<span className=" k-icon k-i-delete" title={t("Global_Delete_Text")}></span>&nbsp;&nbsp;
                </button>
            ) : ""}
        </div>
    </td>
    );
};
