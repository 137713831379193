import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { getDate } from '@progress/kendo-date-math';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomDatePickerInput, CustomCheckbox, CustomInput, CustomTextArea } from "../../components/CustomComponents"

import ClientContractsService from "../../services/clientContracts.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Insert(props) {

    const { t } = useTranslation();
    let history = useHistory();

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const client = {
        "description": "",
        "creationDate": getDate(new Date()),
        "startDate": getDate(new Date()),
        "endDate": getDate(new Date()),
        "remarks": ""
    };



    const handleSubmit = (dataItem) => {

        // lets see...
        dataItem.creationDate.setHours(24);
        dataItem.startDate.setHours(24);
        dataItem.endDate.setHours(24);

        ClientContractsService.insertClientContract(props.clientId, {
            "isDisabled": dataItem.isDisabled,
            "description": dataItem.description,
            "creationDate": dataItem.creationDate.toISOString().slice(0, 19),
            "startDate": dataItem.startDate.toISOString().slice(0, 19),
            "endDate": dataItem.endDate.toISOString().slice(0, 19),
            "remarks": dataItem.remarks
        }).then(end => {
            history.push("/Clients/" + props.clientId + "/Contracts/" + end.data.contract.contractId + '/Details');
        }).catch((err) => {
            setVisibleError(true);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={client}
                    key={JSON.stringify(client)}
                    render={(formRenderProps) => (
                        <FormElement>
                            <fieldset>
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <b>{t("Client_Details_Label")}</b>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Field
                                                    label={t("Client_Contract_Description_Label")}
                                                    name={'description'}
                                                    disabled={!props.isAdmin}
                                                    component={CustomInput}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <Field
                                                    label={t("Client_Contract_IsDisabled_Label")}
                                                    name={'isDisabled'}
                                                    disabled={!props.isAdmin}
                                                    component={CustomCheckbox}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-4">
                                                <Field
                                                    label={t("Client_Contract_CreationDate_Label")}
                                                    name={'creationDate'}
                                                    format={"dd/MMMM/yyyy"}
                                                    disabled={!props.isAdmin}
                                                    component={CustomDatePickerInput}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <Field
                                                    label={t("Client_Contract_StartDate_Label")}
                                                    name={'startDate'}
                                                    format={"dd/MMMM/yyyy"}
                                                    disabled={!props.isAdmin}
                                                    component={CustomDatePickerInput}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <Field
                                                    label={t("Client_Contract_EndDate_Label")}
                                                    name={'endDate'}
                                                    format={"dd/MMMM/yyyy"}
                                                    disabled={!props.isAdmin}
                                                    component={CustomDatePickerInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <Field
                                                    label={t("Client_Remarks_Label")}
                                                    name={'remarks'}
                                                    component={CustomTextArea}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <br />
                            <div className="float-end k-form-buttons">
                                <button
                                    type={'submit'}
                                    disabled={!formRenderProps.allowSubmit}
                                    className="k-button"
                                >
                                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                                </button>
                            </div>
                        </FormElement>)}
                />
            </LocalizationProvider>
        </div>
    );
}
