import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForLicenseTags = axios.create();

axiosApiInstanceForLicenseTags.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForLicenseTags(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class LicenseTagsService {

    getLicenseTagsFiltered(filter, orderBy, page, pageSize) {

        let filterToSend = "";

        if (orderBy)
            filterToSend = orderBy;


        if (page)
            if (filterToSend !== "")
                filterToSend += "&page=" + page;
            else
                filterToSend = "?page=" + page;

        if (pageSize)
            if (filterToSend !== "")
                filterToSend += "&pageSize=" + pageSize;
            else
                filterToSend = "?pageSize=" + pageSize;

        if (filter)
            if (filterToSend !== "")
                filterToSend += "&filter=" + filter;
            else
                filterToSend = "?filter=" + filter;

        return axiosApiInstanceForLicenseTags.get(API_URL + '/V3/LicenseTags' + filterToSend, { headers: authHeader() });
    }

    getLicenseTagByTagName(tagName) {
        return axiosApiInstanceForLicenseTags.get(API_URL + '/V3/LicenseTags/' + tagName, { headers: authHeader() });
    }

    deleteLicenseTag(tagName) {
        return axiosApiInstanceForLicenseTags.delete(API_URL + '/V3/LicenseTags/' + tagName, { headers: authHeader() });
    }

    updateLicense(tagName, LicenseTag) {
        return axiosApiInstanceForLicenseTags.put(API_URL + '/V3/LicenseTags/' + tagName, LicenseTag, { headers: authHeader() });
    }

    insertLicense(LicenseTag) {
        return axiosApiInstanceForLicenseTags.post(API_URL + '/V3/LicenseTags', LicenseTag, { headers: authHeader() });
    }

    async getListOfApplications() {


        let getTotal = await axiosApiInstanceForLicenseTags.get(API_URL + '/V3/Applications/', { headers: authHeader() });

        let getListOfApps = await axiosApiInstanceForLicenseTags.get(API_URL + '/V3/Applications/?pageSize='+ getTotal.data.totalResults+'&orderBy=ApplicationName', { headers: authHeader() });

        return getListOfApps.data;


    }
}

export default new LicenseTagsService();
