import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL =  process.env.REACT_APP_API_URL; //'https://caedis-cas-licensing-api-test.azurewebsites.net'//'https://licapi.agrifoodatsolutions.com';
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class HomeService {

    getHome() {
        return axiosApiInstanceForValidation.get(API_URL + '/V1/Home', { headers: authHeader() });
    }
   
    
}

export default new HomeService();
