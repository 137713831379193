import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton';

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomInput, CustomDatePickerInput, CustomTextArea } from "../../components/CustomComponents"

import LicenseRequestsService from "../../services/licenserequests.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);


loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Edit(props) {

    const { t } = useTranslation();
    let history = useHistory();
    const [loading, setLoading] = React.useState(true);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });


    const [licenseRequest, setLicenseRequest] = React.useState({
        "contactEmail": "",
        "contactName": "",
        "appId": "",
        "companyName": "",
        "machineCode": "",
        "remarks": "",
        "creationDate": Date.now.toJSON,
        "windowsOSVersion": "",
        "ipAddress": "",
        "windowsUserName": "",
        "licenseRequestId": "",
        "existLicense": false
    });

    const [applicationsData, setApplicationsData] = React.useState([]);
    const [selectedApplication, setSelectedApplication] = React.useState(null);

    React.useEffect(() => {

        const getDataFromApps = async () => {
            let data = await LicenseRequestsService.getListOfApplications();
            setSelectedApplication(data.find(x => x.appId === props.licenseRequest.appId));
            setApplicationsData(data);

        }

        if (props.licenseRequest.licenseRequestId !== "") {
            setLicenseRequest({
                "contactEmail": props.licenseRequest.contactEmail,
                "contactName": props.licenseRequest.contactName,
                "appId": props.licenseRequest.appId,
                "companyName": props.licenseRequest.companyName,
                "machineCode": props.licenseRequest.machineCode,
                "remarks": props.licenseRequest.remarks,
                "creationDate": new Date(props.licenseRequest.creationDate),
                "windowsOSVersion": props.licenseRequest.windowsOSVersion,
                "ipAddress": props.licenseRequest.ipAddress,
                "windowsUserName": props.licenseRequest.windowsUserName,
                "licenseRequestId": props.licenseRequest.licenseRequestId,
                "existLicense": props.licenseRequest.existLicense

            });

            getDataFromApps();

            setLoading(false);

        }
    }, [props]);

    const handleSubmit = React.useCallback((dataItem) => {

        LicenseRequestsService.updateLicenseRequest(licenseRequest.licenseRequestId, {
            "appId": selectedApplication.appId,
            "contactEmail": dataItem.contactEmail,
            "contactName": dataItem.contactName,
            "applicationId": dataItem.applicationId,
            "companyName": dataItem.companyName,
            "machineCode": dataItem.machineCode,
            "remarks": dataItem.remarks,
            "creationDate": new Date(dataItem.creationDate),
            "windowsOSVersion": dataItem.windowsOSVersion,
            "ipAddress": dataItem.ipAddress,
            "windowsUserName": dataItem.windowsUserName,
            "licenseRequestId": dataItem.licenseRequestId,
            "existLicense": dataItem.existLicense

        }).then(() => {
            history.push("/LicenseRequests/" + licenseRequest.licenseRequestId + "/Details");
        }
        ).catch((err) => {
            setVisibleError(true);
            setLoading(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }, [history, licenseRequest, selectedApplication]);

    const formCallback = React.useCallback((formRenderProps) => {

        const changedSelectedApp = e => {
            setSelectedApplication(e.value);
            formRenderProps.onChange("appId", { value: e.value.appId });
        };


        return (<FormElement>
            <fieldset>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("LicenseRequests_Details_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseRequest_LicenseRequestId_Label")}
                                    name={'licenseRequestId'}
                                    loading={loading}
                                    component={CustomInput}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-lg-2">
                                <Field
                                    label={t("LicenseRequest_CreationDate_Label")}
                                    name={'creationDate'}
                                    format="dd/MM/yyyy"
                                    loading={loading}
                                    disabled={true}
                                    component={CustomDatePickerInput}
                                />
                            </div>
                            <div className="col-lg-2">
                                {loading ? <Skeleton /> : <DropDownList
                                    label={t("LicenseRequest_Application_Label")}
                                    name="application"
                                    textField="applicationName"
                                    data={applicationsData}
                                    dataItemKey="applicationId"
                                    style={{ width: '100%' }}
                                    value={selectedApplication}
                                    disabled={true}
                                    onChange={changedSelectedApp}
                                />}
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseRequest_MachineCode_Label")}
                                    name={'machineCode'}
                                    loading={loading}
                                    disabled={true}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseRequest_CompanyName_Label")}
                                    name={'companyName'}
                                    loading={loading}
                                    disabled={true}
                                    component={CustomInput}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseRequest_ContactName_Label")}
                                    name={'contactName'}
                                    loading={loading}
                                    disabled={true}
                                    component={CustomInput}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseRequest_ContactEmail_Label")}
                                    name={'contactEmail'}
                                    loading={loading}
                                    disabled={true}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseRequest_WindowsOSVersion_Label")}
                                    name={'windowsOSVersion'}
                                    loading={loading}
                                    disabled={true}
                                    component={CustomInput}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseRequest_WindowsUserName_Label")}
                                    name={'windowsUserName'}
                                    loading={loading}
                                    disabled={true}
                                    component={CustomInput}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseRequest_IPAddress_Label")}
                                    name={'ipAddress'}
                                    loading={loading}
                                    disabled={true}
                                    component={CustomInput}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <Field
                                    label={t("LicenseRequest_Remarks_Label")}
                                    name={'remarks'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomTextArea}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            {props.isAdmin ? <div className="float-end k-form-buttons">
                <button
                    type={'submit'}
                    disabled={!formRenderProps.allowSubmit}
                    className="k-button"
                >
                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                </button>
            </div> : ""}
        </FormElement>)
    }, [applicationsData, loading, t, selectedApplication, props]);

    const closeErrorWrapper = React.useCallback(() => { setVisibleError(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorWrapper}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorWrapper}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={licenseRequest}
                        key={JSON.stringify(licenseRequest)}
                        render={formCallback}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
