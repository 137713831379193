import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { CustomInput, CustomDatePickerInput, CustomTextArea, CustomCheckbox } from "../../components/CustomComponents"
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import ContractsService from "../../services/contracts.service";
import ContractTypesServices from "../../services/contractType.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Insert() {

    const { t } = useTranslation();
    let history = useHistory();

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const contractData = {
        "clientId": "",
        "contractTypeId": -1,
        "isDisabled": false,
        "creationDate": new Date(),
        "startDate": new Date(),
        "endDate": new Date(),
        "remarks": "",
        "description": ""
    };

    const [clients, setClients] = React.useState([]);
    const [selectedClient, setSelectedClient] = React.useState({ clientId: -1, name: "", remarks: '' });

    const [contractTypes, setContractTypes] = React.useState([]);
    const [selectedContractType, setSelectedContractType] = React.useState({ contractTypeId: -1, name: "", remarks: '' });

    React.useEffect(() => {

        const getData = async () => {

            let listOfClients = await ContractsService.getAllClients();
            setClients(listOfClients);

        }

        const getDataContractTypes = async () => {

            let listOfContracts = await ContractTypesServices.getListOfContractTypes();
            setContractTypes(listOfContracts);

        }

        getData();
        getDataContractTypes();

    }, []);

    const handleSubmitWrapper = React.useCallback((dataItem) => {

        // lets see...
        dataItem.creationDate.setHours(24);
        dataItem.startDate.setHours(24);
        dataItem.endDate.setHours(24);

        ContractsService.insertContract({
            "clientId": selectedClient.clientId,
            "contractTypeId": selectedContractType.contractTypeId,
            "isDisabled": dataItem.isDisabled,
            "creationDate": dataItem.creationDate,
            "startDate": dataItem.startDate,
            "endDate": dataItem.endDate,
            "remarks": dataItem.remarks,
            "description": dataItem.description
        }).then(end => {
            history.push("/contracts/" + end.data.contract.contractId + "/Details");
        }).catch((err) => {
            setVisibleError(true);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }, [history, selectedClient.clientId, selectedContractType]);

    const editSelectedClient = React.useCallback(e => {
        setSelectedClient(e.value);
    }, []);

    const editSelectedContractTypes = React.useCallback(e => {
        setSelectedContractType(e.value);
    }, []);

    const editorFormWrapper = React.useCallback(
        (formRenderProps) => (
            <FormElement>
                <fieldset>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <b>{t("Contract_Details_Label")}</b>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-10">
                                    <Field
                                        label={t("Contract_Description_Label")}
                                        name={'description'}
                                        component={CustomInput}
                                    />
                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <DropDownList
                                        label={t("Contract_Client_Label")}
                                        name={'client'}
                                        data={clients}
                                        value={selectedClient}
                                        textField="name"
                                        dataItemKey="clientId"
                                        onChange={editSelectedClient}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <DropDownList
                                        label={t("Contract_ContractType_Label")}
                                        name={'contractTypeId'}
                                        data={contractTypes}
                                        value={selectedContractType}
                                        textField="name"
                                        dataItemKey="contractTypeId"
                                        onChange={editSelectedContractTypes}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("Contract_IsDisabled_Label")}
                                        name={'isDisabled'}
                                        component={CustomCheckbox}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <Field
                                        label={t("Contract_CreationDate_Label")}
                                        name={'creationDate'}
                                        format={"dd MMMM yyyy"}
                                        component={CustomDatePickerInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("Contract_StartDate_Label")}
                                        name={'startDate'}
                                        format={"dd MMMM yyyy"}
                                        component={CustomDatePickerInput}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("Contract_EndDate_Label")}
                                        name={'endDate'}
                                        format={"dd MMMM yyyy"}
                                        component={CustomDatePickerInput}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("Contract_Remarks_Label")}
                                        name={'remarks'}
                                        component={CustomTextArea}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <br />
                <div className="float-end k-form-buttons">
                    <button
                        type={'submit'}
                        disabled={!formRenderProps.allowSubmit}
                        className="k-button"
                    >
                        <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                    </button>
                </div>
            </FormElement>),
        [t, clients, selectedClient, editSelectedClient, contractTypes, selectedContractType, editSelectedContractTypes]);

    const closeErrorWrapper = React.useCallback(() => { setVisibleError(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorWrapper}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorWrapper}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmitWrapper}
                        initialValues={contractData}
                        key={JSON.stringify(contractData)}
                        render={editorFormWrapper}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
