import * as React from 'react';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomInput, CustomCheckbox, CustomTextArea } from "../../components/CustomComponents"

import LicenseTagsService from "../../services/licenseTags.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);


loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Edit(props) {

    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const [licenseTag, setLicenseTag] = React.useState({
        "applicationId": -1,
        "tagName": "",
        "remarks": "",
        "isDisabled": false
    });

    const [licenseTagEdit, setLicenseTagEdit] = React.useState({
        "applicationId": -1,
        "tagName": "",
        "remarks": "",
        "isDisabled": false
    });

    const [applicationsData, setApplicationsData] = React.useState([]);
    const [selectedApplication, setSelectedApplication] = React.useState(null);


    React.useEffect(() => {

        const process = async () => {

            let data = await LicenseTagsService.getListOfApplications();
            setApplicationsData(data.contract);
            setSelectedApplication(data.contract.find(x => x.applicationId === props.licenseTag.applicationId));
            setLoading(false);
        }

        if (props.licenseTag !== undefined) {
       
            setLicenseTagEdit({
                "applicationId": props.licenseTag.applicationId,
                "tagName": props.licenseTag.tagName,
                "remarks": props.licenseTag.remarks,
                "isDisabled": props.licenseTag.isDisabled

            });
            setLicenseTag({
                "applicationId": props.licenseTag.applicationId,
                "tagName": props.licenseTag.tagName,
                "remarks": props.licenseTag.remarks,
                "isDisabled": props.licenseTag.isDisabled

            });
            process();
        }
    }, [props]);

    const handleSubmit = React.useCallback(() => {

        LicenseTagsService.updateLicense(licenseTag.tagName, {
            "applicationId": selectedApplication.applicationId,
            "tagName": licenseTagEdit.tagName,
            "remarks": licenseTagEdit.remarks,
            "isDisabled": licenseTagEdit.isDisabled

        }).then((response) => {
            setLicenseTag(response.data.contract);
        }
        ).catch((err) => {
            setVisibleError(true);
            setLoading(false);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });

    }, [licenseTag, licenseTagEdit, selectedApplication]);


    const changedSelectedClientCallback = React.useCallback(e => {
        setSelectedApplication(e.value);
    }, []);

    const changedIsDisabledCallback = React.useCallback(e => {
        setLicenseTagEdit(prev => ({...prev, isDisabled: e.value }));
    }, []);

    const changedRemarksCallback = React.useCallback(e => {
        setLicenseTagEdit(prev => ({ ...prev, remarks: e.value }));
    }, []);

    const formCallback = React.useCallback(() => (
        <FormElement>
            <fieldset>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <b>{t("LicenseTags_Details_Label")}</b>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4">
                                <Field
                                    label={t("LicenseTag_TagName_Label")}
                                    name={'tagName'}
                                    loading={loading}
                                    component={CustomInput}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-lg-4" >
                                <DropDownList
                                    label={t("LicenseTag_Application_Label")}
                                    name="application"
                                    textField="applicationName"
                                    data={applicationsData}
                                    dataItemKey="applicationId"
                                    style={{ width: '100%' }}
                                    value={selectedApplication}
                                    disabled={!props.isAdmin}
                                    onChange={changedSelectedClientCallback}
                                />
                            </div>
                            <div className="col-lg-2">
                                <Field
                                    label={t("LicenseTag_IsDisabled_Label")}
                                    name={'isDisabled'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomCheckbox}
                                    onChange={changedIsDisabledCallback}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <Field
                                    label={t("LicenseTag_Remarks_Label")}
                                    name={'remarks'}
                                    loading={loading}
                                    disabled={!props.isAdmin}
                                    component={CustomTextArea}
                                    onChange={changedRemarksCallback}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            {props.isAdmin ? <div className="float-end k-form-buttons">
                <button
                    onClick={handleSubmit}
                    //disabled={!formRenderProps.allowSubmit}
                    className="k-button"
                >
                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                </button>
            </div> : ""}
        </FormElement>), [applicationsData, loading, props, selectedApplication, t, 
        changedSelectedClientCallback, handleSubmit, changedIsDisabledCallback, changedRemarksCallback]);

    const closeErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        initialValues={licenseTag}
                        key={JSON.stringify(licenseTag)}
                        render={formCallback}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div >
    );
}
