import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import { toODataString } from '@progress/kendo-data-query';
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";
import { ListOfPaged, MinimumPage } from "../../classes/consts";
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import ContractTypesServices from "../../services/contractType.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');


export default function List(props) {

    const { t } = useTranslation();

    // Loading Hooks
    const [loading, setLoading] = React.useState(true);

    // Delete Event Hooks
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [dataItemToRemove, setDataItemToRemove] = React.useState({});

    // Data Hooks
    const [contractTypes, setContractTypes] = React.useState([]);

    // Grid Hooks
    const [orderByString, setOrderByString] = React.useState("");
    const [sortState, setSortState] = React.useState([]);
    const [pageState, setPageState] = React.useState({ skip: 0, take: MinimumPage() });
    const [total, setTotal] = React.useState(0);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Aspect Functions

    const CommandCell = React.useCallback(cellProps => (
        <CustomListCommandCellWithRemove
            {...cellProps}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/contractTypes/' + cellProps.dataItem.contractTypeId + '/Details'}
            isAdmin={props.isAdmin}
        />
    ), [props]);


    const loadingPanel = 
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>;

    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }
        // default rendering for this cell
        return tdElement;
    }, []);


    React.useEffect(() => {
        setLoading(true);
        ContractTypesServices.getContractTypeFiltered("", "", Math.ceil((pageState.skip + 1) / pageState.take), pageState.take)
            .then(response => response.data)
            .then(json => {
                setContractTypes(json.contract);
                setTotal(json.totalResults);
                setLoading(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
            });
    }, [pageState]);


    const remove = (dataItem) => {

        setDataItemToRemove(dataItem);
        setVisibleRemove(true);

    }

    const removeDataItem = () => {
        ContractTypesServices.deleteContractType(dataItemToRemove.contractTypeId).then(() => {
            setDataItemToRemove({});
            loadData();
            setVisibleRemove(false);
        }).catch((err) => {
            setVisibleError(true);
            setDataItemToRemove({});
            setVisibleRemove(false);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }

    const loadData = () => {

        setLoading(true);

        let filter = "";

        ContractTypesServices.getContractTypeFiltered(filter, orderByString, Math.ceil((pageState.skip + 1) / pageState.take), pageState.take)
            .then(response => response.data)
            .then(json => {
                json.contract.map((item) => {
                    if (item.isDisabled === false) {
                        item.isDisabled = true;
                    }
                    else {
                        item.isDisabled = false;
                    }
                    return item;
                })
                setContractTypes(json.contract);
                setTotal(json.totalResults);
                setLoading(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
            });
    }

    const sortChange = React.useCallback((event) => {
        let oDataString = toODataString({ sort: event.sort });
        oDataString = oDataString.replace("name", "Name");
        oDataString = oDataString.replace("$orderby", "?orderBy");
        setOrderByString(oDataString);
        setSortState(event.sort);
    }, []);

    const pageChange = React.useCallback((event) => {
        setPageState({ ...pageState, skip: event.page.skip, take: event.page.take });
    }, [pageState]);

    React.useEffect(loadData, [sortState, pageState, orderByString]);

    const closeErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);
    const closeRemoveCallback = React.useCallback(() => { setVisibleRemove(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {visibleRemove && (
                <Dialog title={t("Global_RemoveDialog_Title")} onClose={closeRemoveCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{t("Global_RemoveDialog_Text") + " \"" + dataItemToRemove.tagName + "\" ?"} </b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => removeDataItem()}>
                            {t("Global_OKButton_Text")}
                        </button>
                        <button className="k-button" onClick={closeRemoveCallback}>
                            {t("Global_CancelButton_Text")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <br />
                    {loading && loadingPanel}
                    <Grid
                        editField='inEdit'
                        cellRender={loadingCell}
                        pageable={{
                            pageSizes: ListOfPaged(),
                        }}
                        data={contractTypes}
                        total={total}
                        skip={pageState.skip}
                        take={pageState.take}
                        scrollable={'none'}
                        sortable={{
                            allowUnsort: true,
                            mode: "single"
                        }}
                        sort={sortState}
                        onSortChange={sortChange}
                        onPageChange={pageChange}
                    >
                        {props.isAdmin ? <GridToolbar>
                            <div>
                                <Link to={'/ContractTypes/Create'}>
                                    <button title={t("LicenseTags_Button_Tooltip_Create")} className="k-button k-primary">
                                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                                    </button>
                                </Link>
                            </div>
                        </GridToolbar> : ""}
                        <Column cell={CommandCell} width={props.isAdmin ? "130px" : "80px"} sortable={false} filterable={false} />
                        <Column field="name" title={t("ContractTypes_Name_Label")} filterable={false} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}

