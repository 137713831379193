export function ProcessError(err) {


    if (err.response.data)
        if (err.response.data.statusMessage)
            return err.response.data.statusMessage;

    if (err.message)
        return err.message;
    else
        return "Error";



}

export function ErrorCode(err) {

    if (err.response.data)
        if (err.response.data.status)
            return err.response.data.status;


    if (err.name)
        return err.name;
    else
        return "Error";


} 