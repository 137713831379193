import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy, toODataString } from '@progress/kendo-data-query';
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { FloatingLabel } from "@progress/kendo-react-labels";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DatePicker } from "@progress/kendo-react-dateinputs";

import { ListOfPaged, MinimumPage } from "../../classes/consts";
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import LicencesService from "../../services/licenses.service";
import ClientsService from "../../services/clients.service";
import LicenseRequestService from "../../services/licenserequests.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function List(props) {

    const { t } = useTranslation();

    // Grid Hooks
    const [sortState, setSortState] = React.useState([{
        field: "creationDate",
        dir: "desc",
    },]);
    const [pageState, setPageState] = React.useState({ skip: 0, take: MinimumPage() });
    const [total, setTotal] = React.useState(0);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Delete Event Hooks
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [dataItemToRemove, setDataItemToRemove] = React.useState({});

    // Data Hooks
    const [licencesData, setLicencesData] = React.useState([]);
    const [applicationsData, setApplicationsData] = React.useState([]);
    const [selectedApplication, setSelectedApplication] = React.useState(null);

    // Loading Hooks
    const [loading, setLoading] = React.useState(true);
    const [loadingClients, setLoadingClients] = React.useState(true);
    const [refresh, setRefresh] = React.useState(false);

    // Filter Hooks
    const [clientsFilter, setClientsFilter] = React.useState([]);
    const [filterData, setFilterData] = React.useState({ active: null, created: null, appId: "", clientId: 0 });
    const [clientsFilterNames, setClientsFilterNames] = React.useState([]);
    const [stateClientFilter, setStateClientFilter] = React.useState({ data: clientsFilterNames, value: "", });
    const [selectedClientFilter, setSelectedClientFilter] = React.useState("");
    const [filterString, setFilterString] = React.useState("");
    const [statusModes, setStatusModes] = React.useState([]);
    const [statusModeSelected, setStatusModeSelected] = React.useState(null);

    // Aspect Functions

    const CommandCell = React.useCallback(cellProps => (
        <CustomListCommandCellWithRemove
            {...cellProps}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/licenses/' + cellProps.dataItem.licenseId + '/""/Details'}
            isAdmin={props.isAdmin}

        />
    ), [props]);

    const AllowUpdatesCommandCell = React.useCallback(cellProps => (
        <td className="k-command-cell" style={{ textAlign: "center" }}>
            {
                (cellProps.dataItem.allowUpdates === true) ? <span className='k-icon k-i-cloud' title={t("Licenses_AllowUpdates_TooltipText")} />
                    : <span className='k-icon k-i-close' title={t("Licenses_NotAllowUpdates_TooltipText")} />
            }

        </td>

    ), [t]);

    const IsDisabledCommandCell = React.useCallback(cellProps => (
        <td className="k-command-cell" style={{ textAlign: "center" }}>
            {
                (cellProps.dataItem.isDisabled === true) ? <span className='k-icon k-i-check' title={t("Licenses_Enabled_TooltipText")} />
                    : <span className='k-icon k-i-close' title={t("Licenses_Disabled_TooltipText")} />
            }

        </td>
    ), [t]);

    const loadingPanel =
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
        ;

    const MachineCodeTruncateCommandCell = React.useCallback(cellProps => (
        <td className="k-command-cell">
            {
                (cellProps.dataItem.machineCode === null) ? "" : cellProps.dataItem.machineCode.slice(0, 20) + "..."

            }

        </td>

    ), []);

    const LicenseCodeTruncateCommandCell = React.useCallback(cellProps => (
        <td className="k-command-cell">
            {
                cellProps.dataItem.licenseCode.slice(0, 20) + "..."
            }

        </td>

    ), []);

    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    }, []);

    // Action Functions

    const remove = (dataItem) => {

        setDataItemToRemove(dataItem);
        setVisibleRemove(true);

    }

    const removeDataItem = () => {
        setLoading(true);
        LicencesService.deleteLicense(dataItemToRemove.licenseId).then(() => {
            setDataItemToRemove({});
            setRefresh(true);
            setVisibleRemove(false);
        }).catch((err) => {
            setDataItemToRemove({});
            setVisibleError(true);
            setVisibleRemove(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }

    // Effects

    React.useEffect(() => {

        setLoadingClients(true);

        ClientsService.getClients()
            .then(response => response.data)
            .then(json => {
                json.splice(0, 0, { clientId: null, name: "", remarks: "" })
                setLoadingClients(false);
                json.splice(0, 0, { clientId: null, name: t("Licenses_ClientFilter_All_Text"), remarks: "" })
                setClientsFilter(json);
                setClientsFilterNames(json.map((e) => e.name));
                setLoadingClients(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });

        LicenseRequestService.getListOfApplications().then(data => {
            data.push({ appId: "", applicationName: t("Global_DropDownFilter_None_Selected") });
            setSelectedApplication({ appId: "", applicationName: t("Global_DropDownFilter_None_Selected") });
            setApplicationsData(data);
        });
    }, [t]);

    React.useEffect(() => {

        let newData = [{ name: t("License_Active_StatusMode_SelectList"), value: 1 },
        { name: t("License_Disabled_StatusMode_SelectList"), value: 2 },
        { name: t("License_All_StatusMode_SelectList"), value: 3 }];

        setStatusModes(newData);

        if (statusModeSelected != null) {
            let newSelected = newData.find(y => y.value === statusModeSelected.value);
            setStatusModeSelected(newSelected);
        } else {
            setStatusModeSelected({ name: t("License_All_StatusMode_SelectList"), value: 3 });
            console.log({ name: t("License_All_StatusMode_SelectList"), value: 3 });
        }

        

    }, [t]);


    React.useEffect(() => {

        const loadData = () => {
            setRefresh(false);
            setLoading(true);

            let oDataString = toODataString({ sort: sortState });
            oDataString = oDataString.replace("name", "Name");
            oDataString = oDataString.replace("clientName", "ClientName");
            oDataString = oDataString.replace("creationDate", "CreationDate");
            oDataString = oDataString.replace("$orderby", "?orderBy");

            LicencesService.getLicensesFiltered(filterString, oDataString, Math.ceil((pageState.skip + 1) / pageState.take), pageState.take)
                .then(response => response.data)
                .then(json => {
                    json.contract.map((item) => {
                        let newDate = new Date(item.creationDate);
                        newDate.setHours(0, 0, 0, 0);
                        item.creationDate = newDate;
                        return item;
                    })
                    setLicencesData(json.contract);
                    setTotal(json.totalResults);
                    setLoading(false);
                }).catch((err) => {
                    setVisibleError(true);
                    setLoading(false);
                    setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
                });
        };

        loadData();

    }, [pageState, sortState, filterString, refresh]);


    const clickFilter = () => {

        let filter = "";

        if (filterData.active === null)
            setFilterData({ ...filterData, active: true });


        if (selectedClientFilter !== "")
            filter += selectedClientFilter;

        if (filterData.appId !== "")
            if (filter === "")
                filter += "AppId eq '" + filterData.appId + "'";
            else
                filter += " and AppId eq '" + filterData.appId + "'";

        if (filterData.clientId !== 0)
            if (filter === "")
                filter += "ClientId eq " + filterData.clientId;
            else
                filter += " and ClientId eq " + filterData.clientId;

        if (filterData.created !== null)
            if (filter === "")
                filter += "CreationDate ge " + filterData.created.toJSON();
            else
                filter += " and CreationDate ge " + filterData.created.toJSON();

        if (filterData.active !== null)
            if (filter === "")
                filter += " IsDisabled eq " + (filterData.active);
            else
                filter += " and IsDisabled eq " + (filterData.active);


        setPageState(prev => ({ ...prev, skip: 1 }))
        setFilterString(filter);


    }

    const filterDataByClient = React.useCallback((value) => {
        const filter = {
            value: value,
            operator: "startswith",
            ignoreCase: true,
        };

        return value ? filterBy(clientsFilterNames, filter) : clientsFilterNames;
    }, [clientsFilterNames]);

    const FilterByClient = React.useCallback((client) => {

        let clientSelected = clientsFilter.find(obj => {
            return obj.name === client;
        })

        if (clientSelected != null) {

            setSelectedClientFilter("ClientId eq " + clientSelected.clientId);
            setStateClientFilter({
                data: filterDataByClient(clientSelected.name),
                value: clientSelected.name,
            });

        } else {

            setSelectedClientFilter("");
            setStateClientFilter({
                data: filterDataByClient(client),
                value: client,
            });
        }

    }, [clientsFilter, filterDataByClient]);

    const sortChange = React.useCallback((event) => {
        setSortState(event.sort);
    }, []);

    const pageChange = React.useCallback((event) => {
        setPageState(prev => ({ ...prev, skip: event.page.skip, take: event.page.take }));
    }, []);


    const closeDialogError = React.useCallback(() => { setVisibleError(false) }, []);
    const closeDialogRemove = React.useCallback(() => { setVisibleRemove(false) }, []);
    const filterClient = React.useCallback(e => FilterByClient(e.target.value), [FilterByClient]);
    const filterAppId = React.useCallback(e => {
        setSelectedApplication(e.value);
        setFilterData(prev => ({ ...prev, appId: e.value.appId }));
    }, []);
    const filterCreationDate = React.useCallback(e => {
        setFilterData(prev => ({ ...prev, created: new Date(e.value) }));
    }, []);
    const filterIsDisabled = React.useCallback(e => {

        if (e.value.value === 1)
            setFilterData(prev => ({ ...prev, active: false }));
        else if (e.value.value === 2)
            setFilterData(prev => ({ ...prev, active: true }));
        else
            setFilterData(prev => ({ ...prev, active: null }));

       setStatusModeSelected(e.value);

    }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeDialogError}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeDialogError}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {visibleRemove && (
                <Dialog title={t("Global_RemoveDialog_Title")} onClose={closeDialogRemove}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{t("Global_RemoveDialog_Text") + " \"" + dataItemToRemove.licenseId + "\" ?"} </b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => removeDataItem()}>
                            {t("Global_OKButton_Text")}
                        </button>
                        <button className="k-button" onClick={closeDialogRemove}>
                            {t("Global_CancelButton_Text")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <div className="card">
                        <div className="card-body container-fluid">
                            <div className="row">
                                <div className="col-lg-2">
                                    <FloatingLabel
                                        style={{ width: "100%" }}
                                        label={t("License_ClientId_Label")}
                                        editorId={"clientsFilterSelector"}
                                        editorValue={loading ? "" : "null"}
                                    >
                                        <AutoComplete
                                            style={{ width: "100%" }}
                                            id={"clientsFilterSelector"}
                                            loading={loadingClients}
                                            data={stateClientFilter.data}
                                            value={stateClientFilter.value}
                                            onChange={filterClient}
                                        />
                                    </FloatingLabel>
                                </div>
                                <div className="col-lg-2">
                                    <DropDownList
                                        label={t("License_Application_Label")}
                                        name="application"
                                        textField="applicationName"
                                        data={applicationsData}
                                        dataItemKey="applicationId"
                                        style={{ width: '100%' }}
                                        value={selectedApplication}
                                        onChange={filterAppId}
                                    />

                                </div>

                                <div className="col-lg-2">
                                    <DatePicker
                                        width={"100%"}
                                        format="d/MM/yyyy"
                                        label={t("Licenses_CreationDate_Label")}
                                        onChange={filterCreationDate}
                                    />
                                </div>

                                <div className="col-lg-1">
                                    <FloatingLabel
                                        style={{ width: "100%" }}
                                        label={t("Licenses_Enabled_Label")}
                                        editorId={"filterIsDisabledSelector"}
                                        editorValue={loading ? "" : "null"}
                                    >
                                        <DropDownList
                                            id={"filterIsDisabledSelector"}
                                            data={statusModes}
                                            textField="name"
                                            dataItemKey="value"
                                            value={statusModeSelected}
                                            onChange={filterIsDisabled}
                                        />
                                    </FloatingLabel>
                                </div>

                                <div className="col-lg-3"></div>
                                <div className="col-lg-2 d-flex">

                                    <button
                                        onClick={clickFilter}
                                        style={{ margin: "0" }}
                                        className="k-button ms-auto mt-auto mb-0"
                                    >
                                        <span className="k-icon k-i-filter" /> &nbsp; {t("Global_Button_Text_Filter")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    {loading && loadingPanel}

                    <Grid
                        editField='inEdit'
                        cellRender={loadingCell}
                        pageable={{
                            pageSizes: ListOfPaged(),
                        }}
                        data={licencesData}
                        total={total}
                        skip={pageState.skip}
                        take={pageState.take}
                        scrollable={'none'}
                        sortable={{
                            allowUnsort: true,
                            mode: "single"
                        }}
                        sort={sortState}
                        onSortChange={sortChange}
                        onPageChange={pageChange}
                    >
                        {props.isAdmin ? <GridToolbar>
                            <div>
                                <Link to={'/licenses/Create'}>
                                    <button title={t("Licenses_Button_Tooltip_Create")} className="k-button k-primary">
                                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                                    </button>
                                </Link>
                            </div>
                        </GridToolbar> : ""}
                        <Column cell={CommandCell} width={props.isAdmin ? "130px" : "80px"} sortable={false} />
                        <Column cell={AllowUpdatesCommandCell} sortable={false} />
                        <Column cell={IsDisabledCommandCell} field="isDisabled" title={" "} sortable={true} />
                        <Column field="creationDate" title={t("Licenses_CreationDate_Label")} format="{0:d}" sorteable={true} />
                        <Column field="clientName" title={t("Licenses_ClientId_Label")} width="250px" />
                        <Column field="appId" title={t("Licenses_AppId_Label")} width="150px" />
                        <Column cell={MachineCodeTruncateCommandCell} title={t("Licenses_MachineKey_Label")} />
                        <Column cell={LicenseCodeTruncateCommandCell} title={t("Licenses_LicenseCode_Label")} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}

