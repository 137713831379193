import * as React from 'react';
import HomeService from "../../services/home.service";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { useTranslation } from "react-i18next";
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { CustomInput } from "../../components/CustomComponents"
import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function List() {

    const { t } = useTranslation();

    const [home, setHome] = React.useState({

        "totalClients": 0,
        "totalLicences": 0,
        "totalLicenseRequests": 0
    });


    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );


    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        HomeService.getHome()
            .then(response => response.data)
            .then(json => {
                setHome(json);
                setLoading(false);
            });
    }, []);

    return (
        <div>
            {loading && loadingPanel}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <Form
                    initialValues={home}
                    key={JSON.stringify(home)}
                    render={() => (
                        <FormElement>
                            <fieldset>
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <b>{t("Home_List_Label")}</b>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Field
                                                    label={t("Home_TotalClients_Label")}
                                                    name={'totalClients'}
                                                    component={CustomInput}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <Field
                                                    label={t("Home_TotalLicenses_Label")}
                                                    name={'totalLicences'}
                                                    disabled={true}
                                                    component={CustomInput}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <Field
                                                    label={t("Home_TotalLicenseRequests_Label")}
                                                    name={'totalLicenseRequests'}
                                                    disabled={true}
                                                    component={CustomInput}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                        </FormElement>)}
                />
            </LocalizationProvider>
        </div>
    );
}

