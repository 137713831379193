import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForContractType = axios.create();

axiosApiInstanceForContractType.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForContractType(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class ContractTypeService {

    getContractTypeFiltered(filter, orderBy, page, pageSize) {

        let filterToSend = "";

        if (orderBy)
            filterToSend = orderBy;


        if (page)
            if (filterToSend !== "")
                filterToSend += "&page=" + page;
            else
                filterToSend = "?page=" + page;

        if (pageSize)
            if (filterToSend !== "")
                filterToSend += "&pageSize=" + pageSize;
            else
                filterToSend = "?pageSize=" + pageSize;

        if (filter)
            if (filterToSend !== "")
                filterToSend += "&filter=" + filter;
            else
                filterToSend = "?filter=" + filter;

        return axiosApiInstanceForContractType.get(API_URL + '/V3/ContractTypes' + filterToSend, { headers: authHeader() });
    }

    getContractTypeByContractTypeId(contractTypeId) {
        return axiosApiInstanceForContractType.get(API_URL + '/V3/ContractTypes/' + contractTypeId, { headers: authHeader() });
    }

    deleteContractType(contractTypeId) {
        return axiosApiInstanceForContractType.delete(API_URL + '/V3/ContractTypes/' + contractTypeId, { headers: authHeader() });
    }

    updateContractType(contractTypeId, ContractType) {
        return axiosApiInstanceForContractType.put(API_URL + '/V3/ContractTypes/' + contractTypeId, ContractType, { headers: authHeader() });
    }

    insertContractType(ContractType) {
        return axiosApiInstanceForContractType.post(API_URL + '/V3/ContractTypes', ContractType, { headers: authHeader() });
    }

    async getListOfContractTypes() {


        let getTotal = await axiosApiInstanceForContractType.get(API_URL + '/V3/ContractTypes/', { headers: authHeader() });

        let getListOfApps = await axiosApiInstanceForContractType.get(API_URL + '/V3/ContractTypes/?pageSize='+ getTotal.data.totalResults, { headers: authHeader() });

        return getListOfApps.data.contract;


    }
}

export default new ContractTypeService();
