import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";

import { CustomInput, CustomCheckbox, CustomDatePickerInput, CustomTextArea } from "../../components/CustomComponents"
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import LicenseService from "../../services/licenses.service";
import LicenseRequestService from "../../services/licenserequests.service";
import ClientsService from "../../services/clients.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Insert(props) {

    const { t } = useTranslation();
    let history = useHistory();


    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Hooks
    const [clients, setClients] = React.useState([]);
    const [selectedClient, setSelectedClient] = React.useState({ clientId: -1, name: "", remarks: '' });
    const [filteredClients, setFilteredClients] = React.useState([]);

    const [license, setLicense] = React.useState({
        "allowUpdates": false,
        "appId": "",
        "creationDate": Date.now.toJSON,
        "licenseCode": "",
        "licenseId": 0,
        "isDisabled": false,
        "clientId": -1,
        "licenseRequestId": props.licenseRequestId ? props.licenseRequestId : "",
        "machineCode": "",
        "remarks": "",
        "maxVersion": ""
    });

    const [applicationsData, setApplicationsData] = React.useState([]);
    const [selectedApplication, setSelectedApplication] = React.useState(null);

    React.useEffect(() => {

        const getDataFromApps = async () => {
            let data = await LicenseRequestService.getListOfApplications();
            setApplicationsData(data);
        }

        getDataFromApps();

        ClientsService.getClients()
            .then(response => response.data)
            .then(json => {
                setClients(json);
                setFilteredClients(json);
            });

    }, [props]);

    React.useEffect(() => {

        if (props.licenseRequestId !== "" && applicationsData.length > 0) {

            LicenseRequestService.getLicenseRequest(props.licenseRequestId)
                .then(response => response.data)
                .then(responseJson => responseJson.contract)
                .then(json => {
                    let selectedApp = applicationsData.filter(y => y.appId === json.appId);
                    if (selectedApp !== undefined)
                        if (selectedApp.length > 0)
                            setSelectedApplication(selectedApp[0]);
                    setLicense({ ...json, machineCode: json.machineCode, appId: json.appId, licenseRequestId: json.licenseRequestId, clientId: json.clientId, creationDate: Date.now.toJSON, windowsUserName: json.windowsUserName  });
                });

        }
    }, [props.licenseRequestId, applicationsData]);

    const filterChange = (event) => {
        setSelectedClient({});
        setFilteredClients(filterData(event.filter));
    };

    const filterData = (filter) => {
        const data = clients.slice();
        return filterBy(data, filter);
    };


    const handleSubmit = React.useCallback(async (dataItem) => {

        let resultId = "";

        try 
        {

            let resultData = await LicenseService.insertLicense({
                "allowUpdates": dataItem.allowUpdates,
                "appId": selectedApplication.appId,
                "creationDate": new Date(dataItem.creationDate),
                "licenseCode": dataItem.licenseCode,
                "licenseId": 0,
                "isDisabled": dataItem.isDisabled,
                "clientId": dataItem.clientId.clientId,
                "licenseRequestId": dataItem.licenseRequestId,
                "machineCode": dataItem.machineCode,
                "remarks": dataItem.remarks,
                "maxVersion": dataItem.maxVersion
            });

            resultId = resultData.data.contract.licenseId;

            await LicenseService.insertLicenseTag(resultId, "WindowsUserName", dataItem.windowsUserName);

            history.push("/licenses/" + resultId + "/\"\"/Details");

        }catch(err){
            if(resultId !== "")
                history.push("/licenses/" + resultId + "/\"\"/Details");
            setVisibleError(true);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        }
    }, [history, selectedApplication]);

    const formCallback = React.useCallback((formRenderProps) => {

        const changedSelectedClient = (e) => {
            setSelectedClient(e.value);
            formRenderProps.onChange("clientId", { value: e.value })
        };

        const changedSelectedApp = e => {
            setSelectedApplication(e.value);
            formRenderProps.onChange("appId", { value: e.value.appId });
        };

        return (
            <FormElement>
                <fieldset>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <b>{t("License_Details_Label")}</b>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <DropDownList
                                        label={t("License_ClientId_Label")}
                                        name="clientId"
                                        textField="name"
                                        data={filteredClients}
                                        dataItemKey="clientId"
                                        style={{ width: '100%' }}
                                        value={selectedClient}
                                        filterable={true}
                                        onFilterChange={filterChange}
                                        onChange={changedSelectedClient}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <Field
                                        label={t("License_CreationDate_Label")}
                                        name={'creationDate'}
                                        spinners={true}
                                        format="dd/MM/yyyy"
                                        component={CustomDatePickerInput}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <DropDownList
                                        label={t("License_Application_Label")}
                                        name="application"
                                        textField="applicationName"
                                        data={applicationsData}
                                        dataItemKey="applicationId"
                                        style={{ width: '100%' }}
                                        value={selectedApplication}
                                        onChange={changedSelectedApp}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        label={t("License_MaxVersion_Label")}
                                        name={'maxVersion'}
                                        component={CustomInput}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-2">
                                    <Field
                                        label={t("License_IsDisabled_Label")}
                                        name={'isDisabled'}
                                        component={CustomCheckbox}
                                    />
                                </div> <div className="col-lg-2">
                                    <Field
                                        label={t("License_AllowUpdates_Label")}
                                        name={'allowUpdates'}
                                        component={CustomCheckbox}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("License_Remarks_Label")}
                                        name={'remarks'}
                                        component={CustomTextArea}
                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                    <br />
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <b>{t("License_Details_Codes_Label")}</b>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("License_MachineCode_Label")}
                                        name={'machineCode'}
                                        component={CustomInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("License_LicenseCode_Label")}
                                        name={'licenseCode'}
                                        component={CustomTextArea}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("License_LicenseRequestId_Label")}
                                        name={'licenseRequestId'}
                                        component={CustomInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </fieldset>
                <br />
                <div className="float-end k-form-buttons">
                    <button
                        type={'submit'}
                        disabled={!formRenderProps.allowSubmit}
                        className="k-button"
                    >
                        <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                    </button>
                </div>
            </FormElement>)
    }, [applicationsData, t, clients, selectedApplication, selectedClient]);



    const closeDialogErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeDialogErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeDialogErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={license}
                        key={JSON.stringify(license)}
                        render={formCallback}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
