import React from 'react';
import Edit from '../../models/contractTypes/Edit';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ContractTypeServices from "../../services/contractType.service";
import AuthService from "../../services/auth.service";

export default function EditLicenseTags(props) {
    
    const { t } = useTranslation();
    const { params } = props.match;
    const [contractType, setContractType] = React.useState({
        "contractTypeId" : -1,
        "name" : "",
        "remarks" : "",
        "isDisabled" : false
             
    });

    React.useEffect(() => {

        ContractTypeServices.getContractTypeByContractTypeId(params.contractTypeId)
            .then(response => response.data)
            .then(json => {
                setContractType({
                    "contractTypeId": json.contract.contractTypeId,
                    "name" : json.contract.name,
                    "remarks" : json.contract.remarks,
                    "isDisabled" :json.contract.isDisabled        
                });

            });
    }, [params.contractTypeId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/contractTypes/'}>{t("ContractTypes_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/contractTypes/' + contractType.contractTypeId + '/Details'}>{contractType.name}</Link></li>
                </ol>
            </nav>
            <br />
                <Edit
                    isAdmin={AuthService.isUserAdmin()}
                    contractType={contractType}
                />
           <br />
            <br />
        </div >
    );

}
