import React from 'react';
import List from '../../models/contractTypes/List';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";

export default function ListLicenseTags() {

    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenseTags/'}>{t("ContractTypes_Breadcrumb_Title")}</Link></li>
                </ol>
            </nav>
            <br />


            <List
                isAdmin={AuthService.isUserAdmin()}
            />

            <br />
            <br />
        </div >
    );
}
