import React from 'react';
import Edit from '../../models/license requests/Edit';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LicenseRequestsService from "../../services/licenserequests.service";
import AuthService from "../../services/auth.service";

export default function EditLicenseRequests(props) {
    
    const { t } = useTranslation();
    const { params } = props.match;
    const [licenseRequest, setLicenseRequest] = React.useState({
        "applicationId": "",
        "contactEmail" : "",
        "contactName" : "",
        "appId" : "",
        "companyName" : "",
        "machineCode" : "",
        "remarks" : "",
        "creationDate" : Date.now.toJSON,
        "windowsOSVersion" : "",
        "ipAddress" : "",
        "windowsUserName" : "",
        "licenseRequestId" : "",
        "existLicense" : false
             
    });

    React.useEffect(() => {

        LicenseRequestsService.getLicenseRequest(params.licenseRequestId)
            .then(response => response.data)
            .then(json => {
                setLicenseRequest({
                    "applicationId": json.contract.applicationId,
                    "contactEmail" : json.contract.contactEmail,
                    "contactName" : json.contract.contactName,
                    "appId" : json.contract.appId,
                    "companyName" : json.contract.companyName,
                    "machineCode" : json.contract.machineCode,
                    "remarks" : json.contract.remarks,
                    "creationDate" : new Date(json.contract.creationDate),
                    "windowsOSVersion" : json.contract.windowsOSVersion,
                    "ipAddress" : json.contract.ipAddress,
                    "windowsUserName" : json.contract.windowsUserName,
                    "licenseRequestId" : json.contract.licenseRequestId,
                    "existLicense" :json.contract.existLicense
                  
                });

            });
    }, [params.licenseRequestId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/LicenseRequests/'}>{t("LicenseRequests_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/LicenseRequests/' + licenseRequest.licenseRequestId + '/Details'}>{licenseRequest.licenseRequestId}</Link></li>
                </ol>
            </nav>
            <br />
                <Edit
                    isAdmin={AuthService.isUserAdmin()}
                    licenseRequest={licenseRequest}
                />
           <br />
            <br />
        </div >
    );

}
