import React from 'react';
import Add from '../../models/license requests/Insert';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";

export default function AddLicenseRequests() {

    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenserequests/'}>{t("LicenseRequests_Breadcrumb_Title")}</Link></li>
                </ol>
            </nav>
            <br />

            { AuthService.isUserAdmin() ?

                <Add />

                :

                <b>{t("Global_NotAllowed_Message")}</b>
            }

            <br />
            <br />
        </div >
    );

}
