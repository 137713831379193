import React from 'react';
import Edit from '../../models/applications/Edit';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ApplicationsService from "../../services/applications.service";
import AuthService from "../../services/auth.service";

export default function EditLicenseTags(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [application, setApplication] = React.useState({

        "applicationId": -1,
        "applicationName": "",
        "appId": "",
        "remarks": ""

    });

    React.useEffect(() => {

        ApplicationsService.getApplicationByApplicationId(params.applicationId)
            .then(response => response.data)
            .then(json => {
                setApplication({
                    "applicationId": json.contract.applicationId,
                    "applicationName": json.contract.applicationName,
                    "appId": json.contract.appId,
                    "remarks": json.contract.remarks,
                    "isDisabled": json.contract.isDisabled
                });

            });
    }, [params.applicationId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Applications/'}>{t("Applications_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Applications/' + application.applicationId + '/Details'}>{application.applicationName}</Link></li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Applications/' + params.applicationId + '/Details'} className="nav-link active">{t("Applications_Details_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Applications/' + params.applicationId + '/Tags'} className="nav-link">{t("Applications_Tags_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Edit
                        isAdmin={AuthService.isUserAdmin()}
                        application={application}
                    />
                </div>
            </div>
        </div >
    );

}
