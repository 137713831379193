import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = process.env.REACT_APP_API_URL; //'https://caedis-cas-licensing-api-test.azurewebsites.net'//'https://licapi.agrifoodatsolutions.com';
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class LicenseRequestsService {

    getLicenseRequests() {
        return axiosApiInstanceForValidation.get(API_URL + '/V3/LicenseRequests', { headers: authHeader() });
    }
    
    getLicenseRequestsFiltered(filter, orderBy, page, pageSize) {

        let filterToSend = "";

        if (orderBy)
            filterToSend = orderBy;


        if (page)
            if (filterToSend !== "")
                filterToSend += "&page=" + page;
            else
                filterToSend = "?page=" + page;

        if (pageSize)
            if (filterToSend !== "")
                filterToSend += "&pageSize=" + pageSize;
            else
                filterToSend = "?pageSize=" + pageSize;

        if (filter)
            if (filterToSend !== "")
                filterToSend += "&filter=" + filter;
            else
                filterToSend = "?filter=" + filter;

        return axiosApiInstanceForValidation.get(API_URL + '/V3/LicenseRequests' + filterToSend, { headers: authHeader() });
    }

    getLicenseRequest(licenseRequestId) {
        return axiosApiInstanceForValidation.get(API_URL + '/V3/LicenseRequests/' + licenseRequestId, { headers: authHeader() });
    }

    deleteLicenseRequest(licenseRequestId) {
        return axiosApiInstanceForValidation.delete(API_URL + '/V3/LicenseRequests/' + licenseRequestId, { headers: authHeader() });
    }

    updateLicenseRequest(licenseRequestId, LicenseRequest) {
        return axiosApiInstanceForValidation.put(API_URL + '/V3/LicenseRequests/' + licenseRequestId, LicenseRequest, { headers: authHeader() });
    }

    insertLicenseRequest(LicenseRequest) {
        return axiosApiInstanceForValidation.post(API_URL + '/V3/LicenseRequests', LicenseRequest, { headers: authHeader() });
    }
    
    async getListOfApplications() {

        let getTotal = await axiosApiInstanceForValidation.get(API_URL + '/V3/Applications/', { headers: authHeader() });
        let getListOfApps = await axiosApiInstanceForValidation.get(API_URL + '/V3/Applications/?pageSize='+ getTotal.data.totalResults+'&orderBy=ApplicationName', { headers: authHeader() });
        return getListOfApps.data.contract;

    }
}

export default new LicenseRequestsService();
