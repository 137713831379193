import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import { toODataString } from '@progress/kendo-data-query';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";

import ClientContractsService from "../../services/clientContracts.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function List(props) {

    const { t } = useTranslation();

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Data Hooks
    const [data, setData] = React.useState([]);

    // Delete Event Hooks
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [dataItemToRemove, setDataItemToRemove] = React.useState({});

    // Grid Hooks
    const [orderByString, setOrderByString] = React.useState("");
    const [sortState, setSortState] = React.useState([]);
    const [pageState, setPageState] = React.useState({ skip: 0, take: 10 });
    const [total, setTotal] = React.useState(0);

    // Loading Hooks
    const [loading, setLoading] = React.useState(true);

    // Aspect functions

    const CommandCell = React.useCallback(cellProps => (
        <CustomListCommandCellWithRemove
            {...cellProps}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/clients/' + props.clientId + '/Contracts/' + cellProps.dataItem.contractId + '/Details'}
            isAdmin={props.isAdmin}
        />
    ), [props]);

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const loadingCell = (tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    };

    // Data functions
    const remove = (dataItem) => {

        setDataItemToRemove(dataItem);
        setVisibleRemove(true);

    }

    const removeDataItem = () => {

        setLoading(true);

        ClientContractsService.deleteClientContract(props.clientId, dataItemToRemove.contractId).then(() => {
            setDataItemToRemove({});
            loadData();
            setVisibleRemove(false);
        }).catch(err => {
            setDataItemToRemove({});
            setVisibleError(true);
            setVisibleRemove(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) })
        });

    }

    const sortChange = (event) => {
        let oDataString = toODataString({ sort: event.sort });
        oDataString = oDataString.replace("name", "Name");
        oDataString = oDataString.replace("startDate", "StartDate");
        oDataString = oDataString.replace("creationDate", "CreationDate");
        oDataString = oDataString.replace("$orderby", "?orderBy");
        setOrderByString(oDataString);
        setSortState(event.sort);
    };

    const pageChange = (event) => {
        setPageState({ ...pageState, skip: event.page.skip, take: event.page.take });
    }

    const loadData = () => {

        setLoading(true);

        let filter = "";

        ClientContractsService.getClientContractsFiltered(props.clientId, filter, orderByString, Math.ceil((pageState.skip + 1) / pageState.take), pageState.take)
            .then(response => response.data)
            .then(json => {
                json.contract.map((item) => {
                    item.creationDate = new Date(item.creationDate);
                    item.startDate = new Date(item.startDate);
                    item.endDate = new Date(item.endDate);
                    if (item.isDisabled === false) {
                        item.isDisabled = true;
                    }
                    else {
                        item.isDisabled = false;
                    }
                    return item;
                })
                setData(json.contract);
                setTotal(json.totalResults);
                setLoading(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });

    }

    // Effects

    React.useEffect(loadData, [sortState, pageState, orderByString, props]);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {loading && loadingPanel}
            {visibleRemove && (
                <Dialog title={t("Global_RemoveDialog_Title")} onClose={() => { setVisibleRemove(false); }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{t("Global_RemoveDialog_Text") + " \"" + dataItemToRemove.contractId + "\" ?"} </b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { removeDataItem(); }}>
                            {t("Global_OKButton_Text")}
                        </button>
                        <button className="k-button" onClick={() => { setVisibleRemove(false); }}>
                            {t("Global_CancelButton_Text")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <Grid
                    editField='inEdit'
                    cellRender={loadingCell}
                    pageable={{
                        pageSizes: [10, 20, 50, 100],
                    }}
                    data={data}
                    total={total}
                    skip={pageState.skip}
                    take={pageState.take}
                    scrollable={'none'}
                    sortable={{
                        allowUnsort: true,
                        mode: "single"
                    }}
                    sort={sortState}
                    onSortChange={sortChange}
                    onPageChange={pageChange}

                >
                    {props.isAdmin ? <GridToolbar>
                        <div>
                            <Link to={'/Clients/' + props.clientId + '/Contracts/Add'}>
                                <button title={t("Clients_Button_Tooltip_Create")} className="k-button k-primary">
                                    &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                                </button>
                            </Link>
                        </div>
                    </GridToolbar> : ""}
                    <Column cell={CommandCell} width={props.isAdmin ? "130px" : "80px"} sortable={false} filterable={false} />
                    <Column field="description" title={t("Client_Contract_Description_Label")} />
                    <Column field="creationDate" format="{0:d}" title={t("Clients_Contracts_CreatedDate_Name_Label")} />
                    <Column field="startDate" format="{0:d}" title={t("Clients_Contracts_StartDate_Name_Label")} />
                    <Column field="endDate" format="{0:d}" title={t("Clients_Contracts_EndDate_Name_Label")} />
                </Grid>
            </LocalizationProvider>
        </div>
    );
}

