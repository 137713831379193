import React from 'react';
import Edit from '../../models/contracts/Edit';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ContractsService from "../../services/contracts.service";
import AuthService from "../../services/auth.service";

export default function EditContracts(props) {
    
    const { t } = useTranslation();
    const { params } = props.match;
    const [contractData, setContractData] = React.useState({
        "contractId": -1,
        "contractTypeId": -1,
        "clientId": "",
        "isDisabled": true,
        "creationDate": new Date(),
        "startDate": new Date(),
        "endDate": new Date(),
        "remarks": "",
        "description": ""
    });

    React.useEffect(() => {

        ContractsService.getContract(params.contractId)
            .then(response => response.data)
            .then(json => {
                setContractData({
                    "contractId": json.contract.contractId,
                    "contractTypeId": json.contract.contractTypeId,
                    "clientId": json.contract.clientId,
                    "isDisabled": json.contract.isDisabled,
                    "creationDate": new Date(json.contract.creationDate),
                    "startDate": new Date(json.contract.startDate),
                    "endDate": new Date(json.contract.endDate),
                    "remarks": json.contract.remarks,
                    "description": json.contract.description
                });

            });
    }, [params.contractId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/contracts/'}>{t("Contracts_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/contracts/' + params.contractId + '/Details'}>{params.contractId}</Link></li>
                </ol>
            </nav>
            <br />
                <Edit
                    isAdmin={AuthService.isUserAdmin()}
                    contractData={contractData}
                />
           <br />
            <br />
        </div >
    );

}
