import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomInput, CustomTextArea, CustomCheckbox } from "../../components/CustomComponents"

import ClientsService from "../../services/clients.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Edit(props) {

    const { t } = useTranslation();
    let history = useHistory();

    const [loading, setLoading] = React.useState(true);

    const [client, setClient] = React.useState({

        "clientId": 0,
        "name": "",
        "remarks": ""
    });

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    React.useEffect(() => {

        if (props.client.clientId !== "") {
            setClient({
                "clientId": props.client.clientId,
                "name": props.client.name,
                "remarks": props.client.remarks ?? "",
                "isDisabled": props.client.isDisabled
            });

            setLoading(false);
        }
    }, [props.client]);

    const handleSubmit = (dataItem) => {

        ClientsService.updateClient(client.clientId, {

            "clientId": dataItem.clientId,
            "name": dataItem.name,
            "remarks": dataItem.remarks,
            "isDisabled": dataItem.isDisabled

        }).then(() => {
            history.push("/clients/" + client.clientId + "/Details");
        }
        ).catch((err) => {
            setVisibleError(true);
            setLoading(false);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }


    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={client}
                        key={JSON.stringify(client)}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset>
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <b>{t("Client_Details_Label")}</b>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <Field
                                                        label={t("Client_ClientId_Label")}
                                                        name={'clientId'}
                                                        loading={loading}
                                                        component={CustomInput}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-sm-8">
                                                    <Field
                                                        label={t("Client_Name_Label")}
                                                        name={'name'}
                                                        disabled={!props.isAdmin}
                                                        loading={loading}
                                                        component={CustomInput}
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <Field
                                                        label={t("Client_IsDisabled_Label")}
                                                        name={'isDisabled'}
                                                        loading={loading}
                                                        disabled={!props.isAdmin}
                                                        component={CustomCheckbox}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-sm-12">
                                                    <Field
                                                        label={t("Client_Remarks_Label")}
                                                        name={'remarks'}
                                                        disabled={!props.isAdmin}
                                                        loading={loading}
                                                        component={CustomTextArea}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </fieldset>
                                {props.isAdmin ? <div className="float-end k-form-buttons">
                                    <button
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                        className="k-button"
                                    >
                                        <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                                    </button>
                                </div> : ""}
                            </FormElement>)}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div >
    );
}
