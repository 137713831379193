import React from 'react';
import Edit from '../../models/clients/EditLicense';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ClientService from "../../services/clients.service";
import AuthService from "../../services/auth.service";

export default function DetailsLicense(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [client, setClient] = React.useState({

        "clientId": 0,
        "name": "",
        "remarks": "",
        "isDisabled": false

    });

    React.useEffect(() => {

        ClientService.getClient(params.clientId)
            .then(response => response.data)
            .then(json => {
                setClient({

                    "clientId": json.contract.clientId,
                    "name": json.contract.name,
                    "remarks": json.contract.remarks,
                    "isDisabled": json.contract.isDisabled

                });

            });
    }, [params.clientId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/Home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Clients/'}>{t("Clients_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Clients/' + client.clientId + '/Details'}>{client.name}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Clients/' + client.clientId + '/Licenses'}>{t("Clients_Licenses_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Clients/' + client.clientId + '/Licenses/' + params.licenseId + '/Details'}>{params.licenseId}</Link></li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Clients/' + client.clientId + '/Details/'} className="nav-link">{t("Clients_Details_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Clients/' + client.clientId + '/Contracts'} className="nav-link">{t("Clients_Contracts_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Clients/' + client.clientId + '/Licenses'} className="nav-link active">{t("Clients_Licenses_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <div className="card">
                        <div className="card-header">
                            <ul className="nav nav-tabs card-header-tabs">
                                <li className="nav-item">
                                    <Link to={'/Clients/' + client.clientId + '/Licenses/' + params.licenseId +'/Details'} className="nav-link active">{t("Clients_Licenses_Details_Title")}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/Clients/' + client.clientId + '/Licenses/' + params.licenseId + '/Tags'} className="nav-link">{t("Clients_Licenses_Tags_Title")}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <Edit
                                isAdmin={AuthService.isUserAdmin()}
                                client={client}
                                licenseId={params.licenseId}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />
        </div >
    );

}
