import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { CustomInput, CustomTextArea, CustomCheckbox } from "../../components/CustomComponents"
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import LicenseTagsService from "../../services/licenseTags.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Insert() {

    const { t } = useTranslation();
    let history = useHistory();

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const licenseTag = {
        "tagName": "",
        "remarks": "",
        "existLicense": false
    };

    const handleSubmit = (dataItem) => {

        LicenseTagsService.insertLicense({
            "tagName": dataItem.tagName,
            "remarks": dataItem.remarks,
            "isDisabled": dataItem.isDisabled

        }).then(end => {
            history.push("/licenseTags/" + end.data.contract.tagName + "/Details");
        }).catch((err) => {
            setVisibleError(true);
            setErrorData({  messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }


    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={() => { setVisibleError(false) }}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={licenseTag}
                        key={JSON.stringify(licenseTag)}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset>
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <b>{t("LicenseTags_Details_Label")}</b>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <Field
                                                        label={t("LicenseTags_TagName_Label")}
                                                        name={'tagName'}
                                                        component={CustomInput}
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Field
                                                        label={t("LicenseTag_IsDisabled_Label")}
                                                        name={'isDisabled'}
                                                        component={CustomCheckbox}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-sm-12">
                                                    <Field
                                                        label={t("LicenseTag_Remarks_Label")}
                                                        name={'remarks'}
                                                        component={CustomTextArea}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="float-end k-form-buttons">
                                    <button
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                        className="k-button"
                                    >
                                        <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                                    </button>
                                </div>
                            </FormElement>)} />

                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
