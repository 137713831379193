import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = process.env.REACT_APP_API_URL;
const axiosApiInstanceForValidation = axios.create();

axiosApiInstanceForValidation.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForValidation(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class LicensesService {

    getLicenses() {
        return axiosApiInstanceForValidation.get(API_URL + '/V1/Licenses', { headers: authHeader() });
    }

    getLicensesFiltered(filter, orderBy, page, pageSize) {

        let filterToSend = "";

        if (orderBy)
            filterToSend = orderBy;


        if (page)
            if (filterToSend !== "")
                filterToSend += "&page=" + page;
            else
                filterToSend = "?page=" + page;

        if (pageSize)
            if (filterToSend !== "")
                filterToSend += "&pageSize=" + pageSize;
            else
                filterToSend = "?pageSize=" + pageSize;

        if (filter)
            if (filterToSend !== "")
                filterToSend += "&filter=" + filter;
            else
                filterToSend = "?filter=" + filter;

        return axiosApiInstanceForValidation.get(API_URL + '/V3/Licenses' + filterToSend, { headers: authHeader() });
    }


    getLicenseTagsFiltered(licenseId) {

        return axiosApiInstanceForValidation.get(API_URL + '/V3/Licenses/' + licenseId + '/Tags', { headers: authHeader() });
    }


    getLicense(licenseCode) {
        return axiosApiInstanceForValidation.get(API_URL + '/V3/Licenses/' + licenseCode, { headers: authHeader() });
    }

    getLicenseById(licenseId) {
        return axiosApiInstanceForValidation.get(API_URL + '/V3/Licenses/' + licenseId + '/id', { headers: authHeader() });
    }

    getLicenseTagByTagName(licenseId, tagName) {
        return axiosApiInstanceForValidation.get(API_URL + '/V3/Licenses/' + licenseId + '/Tags/' + tagName, { headers: authHeader() });
    }

    getLicenseByRequestId(licenseRequestId) {
        return axiosApiInstanceForValidation.get(API_URL + '/V3/Licenses/' + licenseRequestId + '/licenseRequestId', { headers: authHeader() });
    }

    deleteLicense(LicenseId) {
        return axiosApiInstanceForValidation.delete(API_URL + '/V3/Licenses/' + LicenseId, { headers: authHeader() });
    }

    deleteLicenseTag(LicenseId, tagName) {
        return axiosApiInstanceForValidation.delete(API_URL + '/V3/Licenses/' + LicenseId + '/Tags/' + tagName, { headers: authHeader() });
    }

    insertLicenseTag(LicenseId, tagName, value) {
        return axiosApiInstanceForValidation.post(API_URL + '/V3/Licenses/' + LicenseId + '/tags/', { tagName: tagName, value: value }, { headers: authHeader() });
    }

    updateLicenseTag(LicenseId, tagName, value) {
        return axiosApiInstanceForValidation.put(API_URL + '/V3/Licenses/' + LicenseId + '/tags/' + tagName, { value: value }, { headers: authHeader() });
    }

    updateLicense(LicenseId, License) {
        return axiosApiInstanceForValidation.put(API_URL + '/V3/Licenses/' + LicenseId, License, { headers: authHeader() });
    }

    insertLicense(License) {
        return axiosApiInstanceForValidation.post(API_URL + '/V3/Licenses', License, { headers: authHeader() });
    }
}

export default new LicensesService();
